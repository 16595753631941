import './funzioni.css'

const Tile = (props) => {
    if(window.innerWidth>670){
        return(
            <div className='tileFunzioni'>
                <div className='flexcolTileFunz'>
                    <div className='flexTitleFunz'>
                        <props.icon className="iconaTileFunz"/>
                        <span className='titoletto'>{props.title}</span>
                    </div>
                    <div className='txtTileFunz testo18'>
                        {props.txt}
                    </div>
                </div>
            </div>
        );
    }else{
        return(
            <div className='tileFunzioni'>
                <div className='flexTitleFunz'>
                    <props.icon className="iconaTileFunz"/>
                    <span className='titoletto'>{props.title}</span>
                </div>             
                <div className='txtTileFunz testo18'>
                    {props.txt}
                </div>
            </div>
        );
    }
}

export default Tile;