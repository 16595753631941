import './performance.css'

function Tile(props) {
  return (
    <div className='tilePerformance'>
        <props.svg className="svgTilePerformance"/>
        <span className='titoletto'>{props.title}</span>
        <span className='testo18'>{props.txt}</span>
    </div>
  );
}

export default Tile;