const propsNavbar = {
    linkHome : "https://www.irinoxprofessional.com/fra/",

    titoli : [
        {
            txt : "SECTEUR",
            link : "",
        },
        {
            txt : "PRODUITS",
            link : "",
        },
        {
            txt : "VIDÉOS",
            link : "https://www.irinoxprofessional.com/fra/vid%C3%A9os/fresh-stories",
        },
        {
            txt : "NOS CLIENTS",
            link : "https://www.irinoxprofessional.com/fra/univers-irinox/",
        },
        {
            txt : "ACADEMY",
            link : "https://www.irinoxprofessional.com/fra/academy/",
        }
    ],

    lingua: "FR",

    propsSettori : {
        tile1 : {
            last : 0,
            txt : "Surgelatión et refroidissement",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/surgelati%C3%B3n-et-refroidissement"
        },
        tile2 : {
            last : 0,
            txt : "Avantages de Irinox",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/avantages-de-irinox"
        },
        tile3 : {
            last : 0,
            txt : "Pâtisserie",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/p%C3%A2tisserie"
        },
        tile4 : {
            last : 0,
            txt : "Glacier",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/glacier"
        },
        tile5 : {
            last : 0,
            txt : "Restauration",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/restauration"
        },
        tile6 : {
            last : 0,
            txt : "Panification",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/panification"
        },
        tile7 : {
            last : 0,
            txt : "Industrie et gastronomie",
            link : "https://www.irinoxprofessional.com/fra/pourquoi-irinox/industrie-et-gastronomie"
        }
    },

    propsProdotti : {
        tile1 : {
            link : [
                "https://www.irinoxprofessional.com/fra/produits/cellules-de-refroidissement-rapide/",
                "https://www.irinoxprofessional.com/fra/produits/cellules-de-refroidissement-rapide/multifresh%C2%AE-next-1/",
                "https://www.irinoxprofessional.com/fra/produits/cellules-de-refroidissement-rapide/easyfresh%C2%AE-next-1/"
            ],
            vediTutti : "Voir tout",
            gamma : [
                "MultiFresh® Next",
                "EasyFresh® Next"
            ],
            serie : "CELLULES DE REFROIDISSEMENT RAPIDE"
        },
        tile2 : {
            link : [
                "https://www.irinoxprofessional.com/fra/produits/roll-in/",
                "https://www.irinoxprofessional.com/fra/produits/roll-in/multifresh%C2%AE-mya-1/",
                ""
            ],
            vediTutti : "Voir tout",
            gamma : [
                "MultiFresh® MyA",
                ""
            ],
            serie : "ROLL-IN"
        },
        tile3 : {
            link : [
                "https://www.irinoxprofessional.com/fra/produits/conservateurs/",
                "https://www.irinoxprofessional.com/fra/produits/conservateurs/cp-next/",
                "https://www.irinoxprofessional.com/fra/produits/conservateurs/ck-next/"
            ],
            vediTutti : "Voir tout",
            gamma : [
                "CP Next",
                "CK Next"
            ],
            serie : "CONSERVATEURS"
        }
    },

    propsBurger : {
        voci : [
            {
                txt : "Vidéos",
                link : "https://www.irinoxprofessional.com/fra/vid%C3%A9os/fresh-stories"
            },
            {
                txt : "Nos clients",
                link : "https://www.irinoxprofessional.com/fra/univers-irinox/"
            },
            {
                txt : "Academy",
                link : "https://www.irinoxprofessional.com/fra/academy/"
            },
            {
                txt : "Entreprise",
                link : "https://www.irinoxprofessional.com/fra/entreprise"
            },
            {
                txt : "Espace Téléchargement",
                link : "https://www.irinoxprofessional.com/fra/area-download/"
            },
            {
                txt : "FreshCloud",
                link : "https://www.irinoxprofessional.com/fra/fresh-cloud"
            },
            {
                txt : "Applications",
                link : "https://www.irinoxprofessional.com/fra/applications/"
            },
            {
                txt : "Contactez-nous",
                link : "https://www.irinoxprofessional.com/fra/contacts"
            },
        ],

        dropdown : [
            {
                grande : 1,
                txtTitle : "Secteur",                     
                miniLinkTxt : [
                    "Surgelatión et refroidissement",
                    "Avantages de Irinox",
                    "Pâtisserie",
                    "Glacier",
                    "Restauration",
                    "Panification",
                    "Industrie et gastronomie"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/surgelati%C3%B3n-et-refroidissement",
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/avantages-de-irinox",
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/p%C3%A2tisserie",
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/glacier",
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/restauration",
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/panification",
                    "https://www.irinoxprofessional.com/fra/pourquoi-irinox/industrie-et-gastronomie"
                ]
            },
            {
                grande : 1,
                txtTitle : "Produits",                        
                miniLinkTxt : [
                    "Cellules de refroidissement rapide",
                    "Roll-in",
                    "Conservateurs"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/fra/produits/cellules-de-refroidissement-rapide/",
                    "https://www.irinoxprofessional.com/fra/produits/roll-in/",
                    "https://www.irinoxprofessional.com/fra/produits/conservateurs/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Support technique",                        
                miniLinkTxt : [
                    "Centre d’Assistance Technique pour l’Italie",
                    "Centre d’Assistance Technique pour l’Étranger",
                    "After Sales Tools"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/fra/cat-italie",
                    "https://www.irinoxprofessional.com/fra/cat-%C3%A9tranger",
                    "https://irinox.aftersalestools.com/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Univers Irinox",                        
                miniLinkTxt : [
                    "News",
                    "Partnership",
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/fra/univers-irinox/news/",
                    "https://www.irinoxprofessional.com/fra/univers-irinox/partnership/"
                ]
            }
        ],
        
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    },

    propsLingua : {
        txtLingua : [
            "Italiano",
            "English",
            "Español",
            "Français",
            "Deutsche"
        ],
        txtBtn: [
            "ANNULER",
            "DÉFINIR LA LANGUE"
        ]
    }
}

export default propsNavbar;