import react from "react";
import './button.css'

function Button(props) {
    const style = {
        minWidth:"unset",
        padding:"unset",
        width:"50px",
        height:"50px",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center"

    }

    return (
        <div className={props.pieno ? 'blu bottone': 'bianco bottone'} style={props.icona&&window.innerWidth<1024?style:{"":""}}>
            {props.icona ? <props.icona className="iconaBottone"/> : null}            
            <div className="txtBtn" style={props.icona && window.innerWidth<1024? {"display":"none"}:{"":""}}>{props.title}</div>
        </div>
    );
}
    
export default Button;