import "./burger.css"
import { ReactComponent as FrecciaGiu } from './../../../risorse/Icone/icona_freccetta_azzurra_giù.svg'
import { ReactComponent as FrecciaSu } from './../../../risorse/Icone/icona_freccetta_azzurra_su.svg'
import { useState } from "react";

function DropDownBurger(props) {

  return (
    <>
        <div className="linkBurger flexTitoloFreccia" onClick={()=>{
            props.dropAperto? props.setDropAperto(0):props.setDropAperto(1)
        }}>
            {props.dropAperto? <FrecciaSu/>:<FrecciaGiu/>}
            {props.txtTitle}
        </div>
        {props.dropAperto? 
            <div className="linkDropAperto showPopUp">
                {props.miniLinkTxt.map(function(object, i){
                    return <a href={props.miniLinkLnk[i]} target="_blank" className="linkTxtDropAperto" key={i}>{props.miniLinkTxt[i]}</a>
                })}
            </div>
        :null}
    </>    
  );
}
  
export default DropDownBurger;