const propsFunzioni = {
    tile1 : {
        title : "Refroidissement Rapide",
        txt : ["La cellule de refroidissement MultiFresh® MyA refroidit rapidement à + 3°C à partir de n'importe quelle température, même bouillante, en respectant la consistance, l'humidité et les propriétés nutritionnelles de chaque aliment."]
    },

    tile2 : {
        title : "Surgélation rapide",
        txt : ["Avec la cellule de refroidissement MultiFresh® MyA, vous choisissez la température, le temps et l'épaisseur dans lesquels retrouver vos aliments décongelés et prêts à l'emploi. Maîtriser la décongélation, c'est ralentir le processus de prolifération bactérienne et conserver intacte la structure des aliments. "]
    },

    tile3 : {
        title : "Décongélation",
        txt : ["Avec la cellule de refroidissement MultiFresh® MyA, vous pouvez régénérez tous vos plats en réglant la température et le temps dans lequel avoir le produit chaud, prêt à être servi, passant automatiquement de + 3 ° C ou -18 ° C à la régénération jusqu'à la température souhaitée, sans l'endommager. "]
    },

    tile4 : {
        title : "Régénération",
        txt : ["Avec MultiFresh® MyA, vous pouvez régénérer tous vos plats en réglant la température et l'heure à laquelle avoir le produit chaud, prêt à être servi, en passant automatiquement de + 3 ° C ou -18 ° C à la régénération jusqu'à la température souhaitée, sans l'abîmer."]
    },

    tile5 : {
        title : "Pasteurisation",
        txt : ["La cellule de refroidissement rapide MultiFresh® MyA pasteurise et refroidit (+ 3 ° C) ou pasteurise et congèle (-18 ° C) les aliments, réduisant ainsi la contamination bactérienne et augmentant leur durée de conservation. "]
    },

    tile6 : {
        title : "Maintien",
        txt : ["Avec la cellule de refroidissement MultiFresh® MYA, tous les types d'aliments peuvent être conservés sans altération à la température souhaitée, tant positive que négative."]
    },

    tile7 : {
        title : "Cuisson à basse température",
        txt : ["La cellule de refroidissement MultiFresh® MyA cuit une Viande ou poisson à basse température, passant automatiquement au refroidissement ou à la congélation directement après la cuisson. Les principaux avantages de ce type de cuisson consistent à rehausser le goût et à obtenir une plus grande uniformité de cuisson."]
    },

    tile8 : {
        title : "Fermentation",
        txt : ["MultiFresh® dispose de cycles de fermentation naturelle sans variations excessives de température et avec contrôle de l'humidité présente dans la chambre, en permettant de programmer l'heure à laquelle trouver les produits parfaitement fermentés."]
    },

    tile9 : {
        title : "Chocolat",
        txt : ["MultiFresh® MyA répond aux besoins des pâtissiers et chocolatiers, avec des cycles dédiés au chocolat, qui maintiennent le bon niveau d'humidité et de température pour une conservation longue et correcte. "]
    },
    
}

export default propsFunzioni;