import "./lingua.css"
import TileLingua from "./tile"
import { ReactComponent as BandieraIta } from './../../../risorse/Icone/bandiere/icona_bandiera_italia.svg'
import { ReactComponent as BandieraGer } from './../../../risorse/Icone/bandiere/icona_bandiera_germania.svg'
import { ReactComponent as BandieraIng } from './../../../risorse/Icone/bandiere/icona_bandiera_inglese.svg'
import { ReactComponent as BandieraSpa } from './../../../risorse/Icone/bandiere/icona_bandiera_spagna.svg'
import { ReactComponent as BandieraFra } from './../../../risorse/Icone/bandiere/icona_bandiera_francia.svg'

import Bottone from "../../common/button"
import { useState } from "react"

function SetLingua(props) {
  const [tmpLingua, setTmpLingua] = useState(props.lingua)

  return (
    <div className="bodyScuro showPopUp">
      <div className='popUpLingua'>
        <div className="riga_bandiere">
          <TileLingua lingua={tmpLingua} setLingua={setTmpLingua} id={0} icona={BandieraIta} txt={props.txtLingua[0]}/>
          <TileLingua lingua={tmpLingua} setLingua={setTmpLingua} id={1} icona={BandieraIng} txt={props.txtLingua[1]}/>
          <TileLingua lingua={tmpLingua} setLingua={setTmpLingua} id={2} icona={BandieraSpa} txt={props.txtLingua[2]}/>
          <TileLingua lingua={tmpLingua} setLingua={setTmpLingua} id={3} icona={BandieraFra} txt={props.txtLingua[3]}/>
          <TileLingua lingua={tmpLingua} setLingua={setTmpLingua} id={4} icona={BandieraGer} txt={props.txtLingua[4]}/>
        </div>
        <div className="bottoni">
          <span onClick={()=>props.setPopLingua(0)}><Bottone title={props.txtBtn[0]} pieno={0}/></span>
          <span onClick={()=>{
            props.setPopLingua(0)
            props.setLingua(tmpLingua)}}><Bottone title={props.txtBtn[1]} pieno={1}/></span>
        </div>
      </div>
    </div>
    
  );
}
  
export default SetLingua;