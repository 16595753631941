import react from "react";
import './settori.css'
import TileSettori from "./tilesSettori";

function PopUpSettori(props) {
    return (
        <div className="popUpSettori">
            <TileSettori {...props.tile1}/>
            <TileSettori {...props.tile2}/>
            <TileSettori {...props.tile3}/>
            <TileSettori {...props.tile4}/>
            <TileSettori {...props.tile5}/>
            <TileSettori {...props.tile6}/>
            <TileSettori {...props.tile7}/>
            {props.tile8?<TileSettori {...props.tile8}/>:null}
        </div>
    );
}
  
export default PopUpSettori;