import ImmagineGwp from './../../../risorse/Immagini/Grafico GWP fra.png'

const propsLanding = {
    propsContattaci : {
        txt : "Contactez-nous",
        link : "https://www.irinoxprofessional.com/fra/contacts"
    },
    /* CO",<sub>2</sub>," */

    propsSostenibilita : {
        sectionTitle : ["Multi",<span style={{"fontStyle":"italic"}}>Fresh® </span>,"MyA CO",<sub>2</sub>],
        arrowTxt : "En savoir plus",
        txt : ["La première gamme de cellules de refroidissement multifonctions à chariot CO",<sub>2</sub>,". Vitesse de refroidissement plus rapide, consommation d'énergie réduite, impact environnemental nul."]
    },

    propsGwp : {
        title : "Une solution écologique.",
        description : ["Le gaz CO",<sub>2</sub>," est un réfrigérant naturel et sans danger pour l'environnement avec un GWP de 1. Le potentiel de réchauffement global (GWP) d'un gaz est la capacité relative d'un gaz à piéger la chaleur dans l'atmosphère au fil du temps par rapport au CO",<sub>2</sub>,". Plus la valeur de l'indice GWP est élevée, plus l'impact d'un gaz sur le réchauffement climatique est important."],
        grafico1 : {
            img: "",
            title: "title",
            txt: ""
        },
        grafico2 : {
            img: ImmagineGwp,
            title: "Global Warming Potential (GWP)",
            txt: ["Le potentiel de réchauffement global (GWP Global Warming Potential) exprime la contribution à l'effet de serre de un gaz, par rapport à l'effet du CO",<sub>2</sub>,", dont le potentiel de référence est égal à 1. Ensuite, chacun des gaz libérés dans l'atmosphère a son propre GWP qui décrit son propre effet sur le changement climatique. Tous les gaz, avec leurs propres GWP, ainsi que les CO",<sub>2</sub>," émis présentant un GWP 1 pour définition, forment le problème éq. CO",<sub>2</sub>," total"]
        },
        grafico3 : {
            img: "",
            title: "title",
            txt: ""
        },
    },

    propsVantaggi : {
        titleTxt : [
            ["Pourquoi choisir le gaz CO",<sub>2</sub>,"?"],
            ["Un choix responsable."],
            "Entretien facile.",
            "Moins d'impact, moins de consommation."
        ],
        descTxt : [
            ["Le gaz CO",<sub>2</sub>," est un réfrigérant naturel, sans danger pour l'environnement. Ininflammable, non toxique et 20% plus efficace."],
            ["L'utilisation du CO",<sub>2</sub>," comme gaz réfrigérant dans diverses applications augmente de plus en plus, du fait que les gaz réfrigérants HFC actuels, tels que le R404A, devront être progressivement éliminés à court terme."],
            ["En plus d'être un gaz facilement disponible, les coûts de l'entretien sont nettement moindres surtout en cas de fuites."],
            ["Grâce à ses caractéristiques physiques, le gaz CO",<sub>2</sub>," absorbe plus de chaleur que les gaz traditionnels du marché, tels que le R404A et le R452A. De plus, il permet l'utilisation de compresseurs qui consomment moins d'électricité."]
        ]
    },
    
    propsMyA : {
        title: ["MultiFresh® MyA CO",<sub>2</sub>],
        txt : [
            "L'inimitable assistant multifonctionnel."
            ,<br></br>,<br></br>,
            "C'est la première gamme de cellules de refroidissement rapide multifonctions sur chariot à gaz réfrigérant R744, plus connu sous le nom de CO",<sub>2</sub>,". C'est la cellule de refroidissement rapide multifonction. La cellule de refroidissement rapide MultiFresh® MyA CO",<sub>2</sub>," est disponible uniquement dans la version multifonction PLUS qui comprend non fonctions froides mais aussi chaudes et est capable de gérer des cycles de fonctionnement avec des températures de +85°C à -35°C."
            ,<br></br>,<br></br>,
            "D'une simple pression sur un bouton, vous pouvez refroidir, congeler, lever, décongeler, régénérer, pasteuriser et cuire à basse température."
            ,<br></br>,<br></br>,
            "Toutes les fonctions ont été soigneusement testées en collaboration avec les professionnels de la restauration les plus prestigieux. Pour chaque aliment et pour chaque processus de production, nous avons établi la bonne température, la bonne ventilation et le taux d'humidité idéal pour un instantané parfait de votre qualité."
            ,<br></br>,<br></br>,
            "Nos cellules de refroidissement rapides garantissent l'extraction de chaleur la plus rapide, même en présence de produits chauds, sans endommager les aliments de quelque manière que ce soit tout en respectant l'environnement. "
        ]
    },

    propsPerformance : {
        title : ["MultiFresh® MyA CO",<sub>2</sub>," est efficacité et performance"],
        subtitle : [
            "Plus rapide",
            "Durabilité",
            "Efficacité"
        ],
        txt : [
            ["Le gaz CO",<sub>2</sub>," vous aide non seulement à devenir plus vert, il est extrêmement efficace en vous offrant des performances améliorées."],
            ["Nous travaillons chaque jour pour prendre soin de notre planète. Avec les cellules de refroidissement IRINOX CO",<sub>2</sub>,", vous aurez vous aussi la possibilité de contribuer à ce changement. "],
            ["Choisir CO",<sub>2</sub>," MultiFresh®, c'est aussi réduire sa consommation d'énergie. Jusqu'à 50% par rapport à la gamme précédente."]
        ]
    },

    propsComposizione : {
        title : ["Solutions IRINOX"],
        subtitle : [
            "Évaporateurs",
            "Unité de condensation",
            "Refroidisseur (optionnel)",
            "L'affichage"
        ],
        desc : [
            ["Les ventilateurs de l'évaporateur sont entièrement en aluminium, avec équilibrage des pales en cuivre et/ou en acier inoxydable, garantissant une hygiène et un nettoyage facile."],
            ["Le groupe de condensation IRINOX est équipé d'un coffret électrique complet avec tous les dispositifs de sécurité et est entièrement construit et testé à 100% par IRINOX dans ses laboratoires avec la cellule. "],
            ["Il est possible de fournir de l'eau de refroidissement à l'unité de condensation au CO",<sub>2</sub>," en connectant un refroidisseur dédié ou en utilisant des refroidisseurs HVAC centralisés existants. Alternativement, il est possible de raccorder un système géothermique existant."],
            ["MyA est la nouvelle interface tactile des cellules de refroidissement rapide multifonction MultiFresh® qui rend l'utilisation de la machine encore plus simple et intuitive. Ses icônes guident parmi les nombreuses fonctions avec un vaste choix de cycles étudiés pour respecter les qualités organoleptiques de chaque aliment. Seule MyA vous offre une personnalisation maximale : vous pourrez modifier les paramètres de chaque cycle avec simplicité et créer le procédé idéal pour chaque produit."]
        ],
        txtChiller: "L'un de ces trois composants doit être connecté pour terminer l'installation.",
        btnTxt: [
            "REFROIDISSEUR DÉDIÉ",
            "REFROIDISSEUR PERSONNALISÉ",
            "SYSTÈME GÉOTHERMIQUE"
        ]
    },

    propsFooter : {
        txt : ["Crée à Corbanese, en Italie, en 1989, Irinox est désormais reconnue dans le monde entier comme le spécialiste des cellules de refroidissement rapide. La production, Made in Italy, est entièrement réalisée en interne, dans les usines de production de la société qui s’étendent sur une surface totale de 9000 m",<sup>2</sup>," aux portes de Trévise."],
        address : ["Via Caduti nei Lager, 1",<br></br>,"31015 Conegliano (TV) - Italy",<br></br>,"Tel. +39 0438 2020",<br></br>,"Fax +39 0438 2023"],
        map : ["INDIQUER SUR LA CARTE","https://www.google.com/maps/place/Irinox+SpA+-+Business+Unit+Professional/@45.9555145,12.312648,15z/data=!4m5!3m4!1s0x0:0x9caf1aa567fe6666!8m2!3d45.9555145!4d12.312648"],
        assistenza : [
            "ASSISTANCE ",
            "https://www.irinoxprofessional.com/fra/demander-de-l-aide"
        ],
        service : [
            "SERVICE ",
            "https://www.irinoxprofessional.com/fra/service"
        ],
        contattaci : [
            "CONTACTEZ-NOUS",
            "https://www.irinoxprofessional.com/fra/contacts"
        ],
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    }
}

export default propsLanding;