import { useEffect, useState } from "react";
import "./burger.css"

import { ReactComponent as FbBianco } from './../../../risorse/Icone/social/fb_bianca.svg'
import { ReactComponent as InBianco } from './../../../risorse/Icone/social/in_bianca.svg'
import { ReactComponent as IgBianco } from './../../../risorse/Icone/social/ig_bianca.svg'
import { ReactComponent as YtBianco } from './../../../risorse/Icone/social/yt_bianca.svg'
import { ReactComponent as FbColorato } from './../../../risorse/Icone/social/fb_colorata.svg'
import { ReactComponent as InColorato } from './../../../risorse/Icone/social/in_colorata.svg'
import { ReactComponent as IgColorato } from './../../../risorse/Icone/social/ig_colorata.svg'
import { ReactComponent as YtColorato } from './../../../risorse/Icone/social/yt_colorata.svg'

import DropDownBurger from "./dropDownGrande";

function BurgerAperto(props) {
    
    const [dropApertoSettori,setDropApertoSettori] = useState(0)
    const [dropApertoProdotti,setDropApertoProdotti] = useState(0)
    
    const [dropApertoAssistenza,setDropApertoAssistenza] = useState(0)
    const [dropApertoMondo,setDropApertoMondo] = useState(0)
    
    const [hoverFb,setHoverFb] = useState(0)
    const [hoverIn,setHoverIn] = useState(0)
    const [hoverIg,setHoverIg] = useState(0)
    const [hoverYt,setHoverYt] = useState(0)

    useEffect(()=>{
        if(dropApertoSettori===1){
            setDropApertoProdotti(0);
        }
    }, [dropApertoSettori])

    useEffect(()=>{
        if(dropApertoProdotti===1){
            setDropApertoSettori(0)
        }
    }, [dropApertoProdotti])

    useEffect(()=>{
        if(dropApertoAssistenza===1){
            setDropApertoMondo(0);
        }
    }, [dropApertoAssistenza])

    useEffect(()=>{
        if(dropApertoMondo===1){
            setDropApertoAssistenza(0)
        }
    }, [dropApertoMondo])

    return (
        <div className="menuBurgerAperto showPopUp">
            <div className="righeBurger">
                <div className="colonnaBurger colonna1Burger">
                    <DropDownBurger dropAperto={dropApertoSettori} setDropAperto={setDropApertoSettori} {...props.dropdown[0]}/>
                    <DropDownBurger dropAperto={dropApertoProdotti} setDropAperto={setDropApertoProdotti} {...props.dropdown[1]}/>
                    <a href={props.voci[0].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[0].txt}</a>
                    <a href={props.voci[1].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[1].txt}</a>
                    <a href={props.voci[2].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[2].txt}</a>
                </div>

                <div className="colonnaBurger colonna2Burger">
                    <a href={props.voci[3].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[3].txt}</a>
                    
                    <a href={props.voci[4].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[4].txt}</a>
                    
                    <a href={props.voci[5].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[5].txt}</a>
                    
                    <a href={props.voci[6].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[6].txt}</a>
                    
                    <DropDownBurger dropAperto={dropApertoAssistenza} setDropAperto={setDropApertoAssistenza} {...props.dropdown[2]}/>
                    
                    <a href={props.voci[7].link} target="_blank" className="linkBurger paddingLinkBurger">{props.voci[7].txt}</a>

                    <DropDownBurger dropAperto={dropApertoMondo} setDropAperto={setDropApertoMondo} {...props.dropdown[3]}/>
                    
                    <div className="rowIconeSocialBurger">
                        {hoverFb?
                            <a title="IconaFB" href={props.fb} target="_blank" className="iconeSocialBurger" onMouseLeave={()=>setHoverFb(0)}><FbColorato/></a>
                        :
                            <a title="IconaFB" href={props.fb} target="_blank" className="iconeSocialBurger" onMouseEnter={()=>setHoverFb(1)}><FbBianco/></a>
                        }

                        {hoverIn?
                            <a href={props.in} target="_blank" className="iconeSocialBurger" onMouseLeave={()=>setHoverIn(0)}><InColorato/></a>
                        :
                            <a href={props.in} target="_blank" className="iconeSocialBurger" onMouseEnter={()=>setHoverIn(1)}><InBianco/></a>
                        }

                        {hoverIg?
                            <a href={props.ig} target="_blank" className="iconeSocialBurger" onMouseLeave={()=>setHoverIg(0)}><IgColorato/></a>
                        :
                            <a href={props.ig} target="_blank" className="iconeSocialBurger" onMouseEnter={()=>setHoverIg(1)}><IgBianco/></a>
                        }

                        {hoverYt?
                            <a href={props.yt} target="_blank" className="iconeSocialBurger" onMouseLeave={()=>setHoverYt(0)}><YtColorato/></a>
                        :
                            <a href={props.yt} target="_blank" className="iconeSocialBurger" onMouseEnter={()=>setHoverYt(1)}><YtBianco/></a>
                        }
                    </div>
                </div>
            </div>
        </div>    
  );
}
  
export default BurgerAperto;