const propsFunzioni = {
    tile1 : {
        title : "Blast Chilling",
        txt : ["The MultiFresh® MyA blast chiller cools quickly to + 3 ° C from any temperature, even boiling, respecting the consistency, humidity and nutritional properties of each food. "]
    },

    tile2 : {
        title : "Freezing",
        txt : ["With the MultiFresh® MyA blast chiller you choose the temperature, time and thickness in which to find your defrosted and ready-to-use foods. Managing defrosting means slowing down the bacterial proliferation process and keeping the food structure intact."]
    },

    tile3 : {
        title : "Thawing",
        txt : ["With the MultiFresh® MyA blast chiller you can regenerate all your dishes by setting the temperature and the time in which to have the hot product, ready to be served, automatically switching from + 3 ° C or -18 ° C to regeneration up to the desired temperature, without damaging it. "]
    },

    tile4 : {
        title : "Regeneration",
        txt : ["With MultiFresh® MyA you can regenerate all your dishes by setting the temperature and time at which to have the hot product, ready to be served, automatically switching from + 3 ° C or -18 ° C to regeneration up to the desired temperature, without damage it. "]
    },

    tile5 : {
        title : "Pasteurization",
        txt : ["The MultiFresh® MyA blast chiller pasteurizes and cools (+ 3 ° C) or pasteurizes and freezes (-18 ° C) foods, reducing bacterial contamination and increasing their shelf life. "]
    },

    tile6 : {
        title : "Holding",
        txt : ["With the MultiFresh® MYA blast chiller, every type of food can be kept without alteration at the desired temperature, both positive and negative."]
    },

    tile7 : {
        title : "Low temperature cooking",
        txt : ["The MultiFresh® MyA blast chiller cooks a low temperature meat or fish, automatically switching to cooling or freezing directly after cooking. The main advantages of this type of cooking consist in enhancing the taste and greater cooking uniformity. "]
    },

    tile8 : {
        title : "Leavening",
        txt : ["The MultiFresh® MyA blast chiller offers cycles for natural leavening, without excessive changes in temperature, by controlling the humidity in the chamber and programming the moment in which to find perfectly leavened products. "]
    },

    tile9 : {
        title : "Chocolate",
        txt : ["MultiFresh® MyA satisfies the needs of confectioners and chocolatiers, with cycles dedicated to chocolate, which maintain the correct level of humidity and temperature for long and correct conservation. "]
    },
    
}

export default propsFunzioni;