import ImmagineGwp from './../../../risorse/Immagini/Grafico GWP ger.png'

const propsLanding = {
    propsContattaci : {
        txt : "Kontakte",
        link : "https://www.irinoxprofessional.com/ita/contatti"
    },

    propsSostenibilita : {
        sectionTitle : ["Multi",<span style={{"fontStyle":"italic"}}>Fresh® </span>,"MyA CO",<sub>2</sub>],
        arrowTxt : "Finde mehr heraus",
        txt : ["Die erste Reihe von Roll-inn Multifunktions-Schnellkühlern mit CO",<sub>2</sub>,". Höhere Schnellkühlgeschwindigkeit, geringerer Energieverbrauch, keine Umweltbelastung."]
    },

    propsGwp : {
        title : "Eine umweltfreundliche Lösung.",
        description : ["CO",<sub>2</sub>,"-Gas ist ein natürliches, umweltfreundliches Kältemittel mit einem GWP von 1. Das Treibhauspotenzial (GWP) eines Gases ist die relative Fähigkeit eines Gases, im Vergleich zu CO",<sub>2</sub>," Wärme in der Atmosphäre einzufangen. Je höher der GWP-Indexwert, desto größer ist der Einfluss eines Gases auf die globale Erwärmung."],
        grafico1 : {
            img: "",
            title: "title",
            txt: ""
        },
        grafico2 : {
            img: ImmagineGwp,
            title: "Global Warming Potential (GWP)",
            txt: ["Das Treibhauspotenzial (GWP) drückt den Beitrag zum Treibhauseffekt aus ein Gas, bezogen auf die Wirkung von CO",<sub>2</sub>,", dessen Bezugspotential gleich 1 ist. Dann werden die einzelnen Gase freigesetzt Atmosphäre hat ihr eigenes GWP, das beschreibt seine eigene klimaverändernde Wirkung. Alle Gase, mit ihren eigenen GWPs, zusammen mit der Freigesetztes CO",<sub>2</sub>," mit GWP 1 für Definition bilden das Problem Gesamt-CO",<sub>2</sub>,"-Äquivalent"]
        },
        grafico3 : {
            img: "",
            title: "title",
            txt: ""
        },
    },

    propsVantaggi : {
        titleTxt : [
            ["Warum CO",<sub>2</sub>,"-Gas wählen?"],
            ["Eine verantwortungsvolle Wahl."],
            "Einfache Wartung.",
            "Weniger Auswirkungen, weniger Verbrauch."
        ],
        descTxt : [
            ["CO",<sub>2</sub>,"-Gas ist ein natürliches Kältemittel, sicher für die Umwelt. Nicht brennbar, ungiftig und 20 % effizienter."],
            ["Der Einsatz von CO",<sub>2</sub>," als Kältemittelgas in verschiedenen Anwendungen nimmt zunehmend zu, da aktuelle HFKW-Kältegase wie R404A kurzfristig schrittweise abgeschafft werden müssen."],
            ["Abgesehen davon, dass es sich um ein leicht verfügbares Gas handelt, sind die Kosten von Wartungsarbeiten sind insbesondere bei Undichtigkeiten deutlich geringer."],
            ["Dank seiner physikalischen Eigenschaften absorbiert CO",<sub>2</sub>,"-Gas mehr Wärme als herkömmliche Gase auf dem Markt, wie R404A und R452A. Darüber hinaus ermöglicht es den Einsatz von Kompressoren, die weniger Strom verbrauchen."]
        ]
    },
    
    propsMyA : {
        title: ["MultiFresh® MyA CO",<sub>2</sub>],
        txt : [
            "Der unnachahmliche multifunktionale Assistent."
            ,<br></br>,<br></br>,
            "Es handelt sich um die erste Reihe von Multifunktions-Schnellkühlern auf einem Wagen mit Kältemittel R744, besser bekannt als CO",<sub>2</sub>,". Es ist der multifunktionale Schnellkühler. Der CO",<sub>2</sub>,"-Schnellkühler MultiFresh® MyA ist nur in der multifunktionalen PLUS-Version erhältlich, die nicht nur die Kalt-, sondern auch die Heißfunktionen umfasst und in der Lage ist, Betriebszyklen mit Temperaturen von + 85 ° C bis -35 ° C zu verwalten."
            ,<br></br>,<br></br>,
            "Auf Knopfdruck können Sie kühlen, einfrieren, aufgehen, auftauen, regenerieren, pasteurisieren und bei niedriger Temperatur garen."
            ,<br></br>,<br></br>,
            "Alle Funktionen wurden in Zusammenarbeit mit den renommiertesten Gastronomieprofis sorgfältig getestet. Für jedes Lebensmittel und für jeden Produktionsprozess haben wir die richtige Temperatur, die richtige Belüftung und die ideale Luftfeuchtigkeit für eine perfekte Momentaufnahme Ihrer Qualität ermittelt."
            ,<br></br>,<br></br>,
            "Unsere Schnellkühler garantieren die schnellste Wärmeentnahme auch bei heißen Produkten, ohne die Lebensmittel in irgendeiner Weise zu beschädigen und gleichzeitig die Umwelt zu schonen."
        ]
    },

    propsPerformance : {
        title : ["MultiFresh® MyA CO",<sub>2</sub>," ist Effizienz und Leistung"],
        subtitle : [
            "Schneller",
            "Nachhaltigkeit",
            "Effizienz"
        ],
        txt : [
            ["CO",<sub>2</sub>,"-Gas hilft Ihnen nicht nur, umweltfreundlicher zu werden, es ist auch äußerst effektiv, indem es Ihnen eine verbesserte Leistung bietet."],
            ["Wir arbeiten jeden Tag daran, unseren Planeten zu schonen. Mit den IRINOX CO",<sub>2</sub>,"-Schnellkühlern haben auch Sie die Möglichkeit, zu dieser Veränderung beizutragen."],
            ["Sich für CO",<sub>2</sub>," MultiFresh® zu entscheiden bedeutet auch, den Energieverbrauch zu senken. Bis zu 50 % im Vergleich zum vorherigen Sortiment."]
        ]
    },

    propsComposizione : {
        title : ["IRINOX-Lösungen"],
        subtitle : [
            "Verdampfer",
            "Verflüssigereinheit",
            "Kühler (optional)",
            "Anzeige"
        ],
        desc : [
            ["Die Verdampferlüfter sind komplett aus Aluminium gefertigt, mit Auswuchtungen der Kupfer- und/oder Edelstahlflügel, was Hygiene und einfache Reinigung garantiert."],
            ["Die IRINOX-Verflüssigereinheit ist mit einem Elektrokasten ausgestattet komplett mit allen Sicherheitsvorrichtungen und wird von IRINOX in seinen Labors zusammen mit der Zelle vollständig gebaut und zu 100 % getestet."],
            ["Das Gerät kann mit Kühlwasser versorgt werden CO",<sub>2</sub>,"-Kondensatoreinheit durch Anschluss eines dedizierten Kühlers oder Verwendung vorhandener zentraler HLK-Kühler. Alternativ besteht die Möglichkeit, eine bestehende Erdwärmeanlage anzuschließen."],
            ["MyA ist die neue Touchscreen- Schnittstelle der Multifunktions- Schnellkühler MultiFresh®, die eine noch einfachere und intuitivere Bedienung der Maschine möglich macht. Ihre Ikonen gestatten den Zugriff auf zahlreiche Funktionen. Durch die umfangreiche Auswahl an Zyklen können die organoleptische Eigenschaften der Lebensmittel bewahrt werden. Nur mit MyA ist maximale Personalisierung möglich: es ist extrem einfach, die Parameter der einzelnen Zyklen zu ändern und den idealen Prozess für jedes Produkt zu schaffen."]
        ],
        txtChiller: "Eine dieser drei Komponenten muss angeschlossen werden, um die Installation abzuschließen.",
        btnTxt: [
            "EIGENER CHILLER",
            "MASSGESCHNEIDERT CHILLER",
            "GEOTHERMISCHES SYSTEM"
        ]
    },

    propsFooter : {
        txt : ["Irinox wird 1989 in Corbanese, Italien gegründet, und ist heute ein weltweit bekannter Spezialist im Schnellkühlen und Schockfrosten. Die Produktion ist vollumfänglich Made in Italy und findet in einem Areal von 9000 m",<sup>2</sup>," bei Treviso statt."],
        address : ["Via Caduti nei Lager, 1",<br></br>,"31015 Conegliano (TV) - Italy",<br></br>,"Tel. +39 0438 2020",<br></br>,"Fax +39 0438 2023"],
        map : ["AUF DER KARTE ANZEIGEN","https://www.google.com/maps/place/Irinox+SpA+-+Business+Unit+Professional/@45.9555145,12.312648,15z/data=!4m5!3m4!1s0x0:0x9caf1aa567fe6666!8m2!3d45.9555145!4d12.312648"],
        assistenza : [
            "KUNDENDIENST",
            "https://www.irinoxprofessional.com/deu/kundendienst-anfordern"
        ],
        service : [
            "SERVICE",
            "https://www.irinoxprofessional.com/deu/service"
        ],
        contattaci : [
            "KONTAKTE",
            "https://www.irinoxprofessional.com/deu/kontakte"
        ],
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    }
}

export default propsLanding;