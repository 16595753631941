import Contattaci from "./contattaci";

import Sostenibilita from "../1_sostenibilita/sostenibilita";
import Gwp from "../2_gwp/gwp";
import Vantaggi from "../3_vantaggi/vantaggi";
import MyA from "../4_MyA/MyA";
import Performance from "../6_performance/performance";
import Composizione from "../7_composizione/composizione";
import Footer from "../0_footer/footer";


import NavbarIta from "../0_navbar/GERnavbar";
import FunzioniIta from "../5_funzioni/GERfunzioni";
import GammaIta from "../8_gamma/GERgamma";

import react, { useEffect, useRef, useState } from "react";


//import dei testi
import propsLanding from "../props/ger/propsLanding";

function Landing(props) { 

    const scrollToDiv = (ref) => window.scrollTo({ top: ref.current.offsetTop,behavior: 'smooth'});
    const el1 = useRef();
    const el2 = useRef();
    const el3 = useRef();
    const el4 = useRef();
    const el5 = useRef();
    const el6 = useRef();
    const el7 = useRef();
    const el8 = useRef();    
    
    return (
        <>
            <NavbarIta lingua={props.lingua} setLingua={props.setLingua}/> 

            <Contattaci {...propsLanding.propsContattaci}/>
            <div className='landingBody marginFromNavbar'>
                <div ref={el1}><Sostenibilita arrowClick={()=> scrollToDiv(el2)} {...propsLanding.propsSostenibilita}/></div>
                
                <div ref={el2}><Gwp arrowClick={()=> scrollToDiv(el3)} {...propsLanding.propsGwp}/></div>
                
                <div ref={el3}><Vantaggi arrowClick={()=> scrollToDiv(el4)} {...propsLanding.propsVantaggi}/></div>
                
                <div ref={el4}><MyA arrowClick={()=> scrollToDiv(el5)} {...propsLanding.propsMyA}/></div>
                
                <div ref={el5}><FunzioniIta arrowClick={()=> scrollToDiv(el6)}/></div>
                
                <div ref={el6}><Performance {...propsLanding.propsPerformance} arrowClick={()=> scrollToDiv(el7)}/></div>

                <div ref={el7}><Composizione arrowClick={()=> scrollToDiv(el8)} {...propsLanding.propsComposizione}/></div>

                <div ref={el8}><GammaIta/></div>

                <Footer {...propsLanding.propsFooter} />
            </div>
        </>
    );
    
  }
  
  export default Landing;