import { useState } from 'react';
import './gamma.css'

const Tile = (props) => {
    const [hover, setHover] = useState(0);

    if (hover===0 && !props.clicked){
        return(
            <div className='tileGamma' onMouseEnter={()=> !props.clicked&&window.innerWidth>720? setHover(1):null}>
                <img src={props.img} className="imgGamma"/>
                <span className='titoletto titolettoGamma'>{props.title}</span>  
                {window.innerWidth<=720?<div className='descriptionGamma testo18'>
                    {props.description}
                </div>:null}              
            </div>
        );
    }else if(!props.clicked){
        return(
            <div className='tileGamma tileGammaHover' onMouseLeave={()=>setHover(0)} onClick={window.innerWidth>720?
                ()=>{
                    props.setClicked(props.clicked? 0:1)
                    props.setSelectedDevice(props.id)
                    setHover(0)
                }:null
            }>
                <img src={props.img} className="imgGamma"/>
                <span className='titoletto titolettoGamma'>{props.title}</span>
                <span className='scopriGamma'>{props.scopri}</span>
            </div>
        );
    }

    if(props.clicked){
        return(
            <div className='tile_descGamma'>
                <div className='tileGamma' onMouseEnter={()=> !props.clicked? setHover(1):null} style={{"maxWidth":"22.5%"}}>
                    <img src={props.img} className="imgGamma"/>
                    <span className='titoletto titolettoGamma'>{props.title}</span>                
                </div>
                {props.clicked?<div className='rigaGamma'></div>:null}
                {props.clicked?<div className='descriptionGamma testo18'>
                    {props.description}
                </div>:null}
            </div>
        );
    }
}

export default Tile;