import './composizione.css'
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/blu.svg'

import Tile from './tile';

import TileUnica from './tileUnica';
import TerzaTile from './terzaTile';

import Evaporatori from './../../risorse/Immagini/evaporatori.png'
import Condensa from './../../risorse/Immagini/unita_condensante.png'
import Chiller from './../../risorse/Immagini/Chiller.png'
import Display from './../../risorse/Immagini/display2.png'


import { useState } from 'react';

function Composizione(props) {

  const [tile, setTile] = useState(0);

  const [selected, setSelected] = useState(0);

  const addStyle = (key) => {

    var style;

    style=selected===key?{"backgroundColor":"#002445","color":"white"}:{"color":"#002445"};

    return style;
  }

  return (
    <div className='composizione' style={tile>0?{"justifyContent":"center"}:{"":""}}>
        {tile===0?<span className='titoloSezione'>{props.title}</span>:null}
        
        {tile===0&&selected===0 ? <svg viewBox="0 0 630 630" fill="none" className='backImageComposizione'>
          <circle onClick={()=>setTile(1)} className="cerchioComposizione" cx="355" cy="260" r="25"/>
          {/* <circle onClick={()=>setTile(2)} className="cerchioComposizione" cx="155" cy="473" r="25"/> */}
          <circle onClick={()=>setTile(3)} className="cerchioComposizione" cx="160" cy="200" r="25"/>
        </svg>:null}

        {selected===1 ? <TileUnica title={props.subtitle[1]} desc={props.desc[1]} img={Condensa}/>: null}
        {selected===2 ? <TerzaTile txt={props.txtChiller} desc={props.desc[2]} btnTxt={props.btnTxt}/>: null}

        {tile===1 ? <Tile setTile={setTile} title={props.subtitle[0]} desc={props.desc[0]} img={Evaporatori}/>:null}
        {/* {tile===2 ? <Tile setTile={setTile} title={""} desc={""} img={"Condensa"}/>:null} */} {/* da valorizzare */}
        {tile===3 ? <Tile setTile={setTile} title={props.subtitle[3]} desc={props.desc[3]} img={Display}/>:null}  {/* da valorizzare */}

        <div className="flexCerchiComposizione">
          <div className='numeriCerchiVantaggi' style={addStyle(0)} onClick={()=>
              {
                setSelected(0)
                setTile(0)
              }
            }>1</div>
          <div className='numeriCerchiVantaggi' style={addStyle(1)} onClick={()=>
              {
                setSelected(1)
                setTile(0)
              }
            }>2</div>
          <div className='numeriCerchiVantaggi' style={addStyle(2)} onClick={()=>
              {
                setSelected(2)
                setTile(0)
              }
            }>3</div>      
        </div>

      <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
    </div>
  );
}

export default Composizione;