import './funzioni.css'
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/blu.svg'

import {ReactComponent as Raffreddamento} from './../../risorse/Icone/Icone funzioni/Raffreddamento.svg'
import {ReactComponent as Surgelazione} from './../../risorse/Icone/Icone funzioni/Surgelazione.svg'
import {ReactComponent as Scongelamento} from './../../risorse/Icone/Icone funzioni/Scongelamento.svg'
import {ReactComponent as Rigenerazione} from './../../risorse/Icone/Icone funzioni/Rigenerazione.svg'
import {ReactComponent as Pastorizzazione} from './../../risorse/Icone/Icone funzioni/Pastorizzazione.svg'
import {ReactComponent as Mantenimento} from './../../risorse/Icone/Icone funzioni/Mantenimento.svg'
import {ReactComponent as Cottura_a_bassa_temperatura} from './../../risorse/Icone/Icone funzioni/Cottura_a_bassa_temperatura.svg'
import {ReactComponent as Lievitazione} from './../../risorse/Icone/Icone funzioni/Lievitazione.svg'
import {ReactComponent as Cioccolato} from './../../risorse/Icone/Icone funzioni/Cioccolato.svg'

import Bottone from "./../common/button"
import { useState } from 'react'
import Tile from './tile'


//import dei testi
import propsFunzioni from './../props/eng/propsFunzioni'


function FunzioniIta(props) {

  const [currentFunc, setCurrentFunc] = useState(0);

  if(window.innerWidth>1023){
    return (
      <div className='funzioni'>
        {window.innerWidth>1279?<div className='column1Funzioni'>
          <div className='flexrowFunzioni'>
            <span onClick={()=>setCurrentFunc(0)}><Bottone {...propsFunzioni.tile1} pieno={currentFunc === 0? 0:1} icona={Raffreddamento}/></span>
            <span onClick={()=>setCurrentFunc(1)}><Bottone {...propsFunzioni.tile2} pieno={currentFunc === 1? 0:1} icona={Surgelazione}/></span>
          </div>
          <div className='flexrowFunzioni'>
            <span onClick={()=>setCurrentFunc(2)}><Bottone {...propsFunzioni.tile3} pieno={currentFunc === 2? 0:1} icona={Scongelamento}/></span>
            <span onClick={()=>setCurrentFunc(3)}><Bottone {...propsFunzioni.tile4} pieno={currentFunc === 3? 0:1} icona={Rigenerazione}/></span>
          </div>
          <div className='flexrowFunzioni'>
            <span onClick={()=>setCurrentFunc(4)}><Bottone {...propsFunzioni.tile5} pieno={currentFunc === 4? 0:1} icona={Pastorizzazione}/></span>
            <span onClick={()=>setCurrentFunc(5)}><Bottone {...propsFunzioni.tile6} pieno={currentFunc === 5? 0:1} icona={Mantenimento}/></span>
          </div>

          <span onClick={()=>setCurrentFunc(6)}><Bottone {...propsFunzioni.tile7} pieno={currentFunc === 6? 0:1} icona={Cottura_a_bassa_temperatura}/></span>

          <div className='flexrowFunzioni'>
            <span onClick={()=>setCurrentFunc(7)}><Bottone {...propsFunzioni.tile8} pieno={currentFunc === 7? 0:1} icona={Lievitazione}/></span>
            <span onClick={()=>setCurrentFunc(8)}><Bottone {...propsFunzioni.tile9} pieno={currentFunc === 8? 0:1} icona={Cioccolato}/></span>
          </div>        
        </div>:
        <div className='column1Funzioni'>
          <span onClick={()=>setCurrentFunc(0)}><Bottone {...propsFunzioni.tile1} pieno={currentFunc === 0? 0:1} icona={Raffreddamento}/></span>
          <span onClick={()=>setCurrentFunc(1)}><Bottone {...propsFunzioni.tile2} pieno={currentFunc === 1? 0:1} icona={Surgelazione}/></span>
        
          <span onClick={()=>setCurrentFunc(2)}><Bottone {...propsFunzioni.tile3} pieno={currentFunc === 2? 0:1} icona={Scongelamento}/></span>
          <span onClick={()=>setCurrentFunc(3)}><Bottone {...propsFunzioni.tile4} pieno={currentFunc === 3? 0:1} icona={Rigenerazione}/></span>
        
          <span onClick={()=>setCurrentFunc(4)}><Bottone {...propsFunzioni.tile5} pieno={currentFunc === 4? 0:1} icona={Pastorizzazione}/></span>
          <span onClick={()=>setCurrentFunc(5)}><Bottone {...propsFunzioni.tile6} pieno={currentFunc === 5? 0:1} icona={Mantenimento}/></span>
          

          <span onClick={()=>setCurrentFunc(6)}><Bottone {...propsFunzioni.tile7} pieno={currentFunc === 6? 0:1} icona={Cottura_a_bassa_temperatura}/></span>

          
          <span onClick={()=>setCurrentFunc(7)}><Bottone {...propsFunzioni.tile8} pieno={currentFunc === 7? 0:1} icona={Lievitazione}/></span>
          <span onClick={()=>setCurrentFunc(8)}><Bottone {...propsFunzioni.tile9} pieno={currentFunc === 8? 0:1} icona={Cioccolato}/></span>
                
        </div>}

        <div className='column2Funzioni'>
          {currentFunc === 0? <Tile icon={Raffreddamento} {...propsFunzioni.tile1}/>:null}
          {currentFunc === 1? <Tile icon={Surgelazione} {...propsFunzioni.tile2}/>:null}
          {currentFunc === 2? <Tile icon={Scongelamento} {...propsFunzioni.tile3}/>:null}
          {currentFunc === 3? <Tile icon={Rigenerazione} {...propsFunzioni.tile4}/>:null}
          {currentFunc === 4? <Tile icon={Pastorizzazione} {...propsFunzioni.tile5}/>:null}
          {currentFunc === 5? <Tile icon={Mantenimento} {...propsFunzioni.tile6}/>:null}
          {currentFunc === 6? <Tile icon={Cottura_a_bassa_temperatura} {...propsFunzioni.tile7}/>:null}
          {currentFunc === 7? <Tile icon={Lievitazione} {...propsFunzioni.tile8}/>:null}
          {currentFunc === 8? <Tile icon={Cioccolato} {...propsFunzioni.tile9}/>:null}
        </div>
        <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
      </div>
    );
  }else{
    return (
      <div className='funzioni'>

        <div className='row1Funzioni'>
          <span onClick={()=>setCurrentFunc(0)}><Bottone {...propsFunzioni.btn1} pieno={currentFunc === 0? 0:1} icona={Raffreddamento}/></span>

          <span onClick={()=>setCurrentFunc(1)}><Bottone {...propsFunzioni.btn2} pieno={currentFunc === 1? 0:1} icona={Surgelazione}/></span>        
        
          <span onClick={()=>setCurrentFunc(2)}><Bottone {...propsFunzioni.btn3} pieno={currentFunc === 2? 0:1} icona={Scongelamento}/></span>

          <span onClick={()=>setCurrentFunc(3)}><Bottone {...propsFunzioni.btn4} pieno={currentFunc === 3? 0:1} icona={Rigenerazione}/></span>        
        
          <span onClick={()=>setCurrentFunc(4)}><Bottone {...propsFunzioni.btn5} pieno={currentFunc === 4? 0:1} icona={Pastorizzazione}/></span>                  
        </div>

        <div className='row2Funzioni'>
          {currentFunc === 0? <Tile icon={Raffreddamento} {...propsFunzioni.tile1}/>:null}
          {currentFunc === 1? <Tile icon={Surgelazione} {...propsFunzioni.tile2}/>:null}
          {currentFunc === 2? <Tile icon={Scongelamento} {...propsFunzioni.tile3}/>:null}
          {currentFunc === 3? <Tile icon={Rigenerazione} {...propsFunzioni.tile4}/>:null}
          {currentFunc === 4? <Tile icon={Pastorizzazione} {...propsFunzioni.tile5}/>:null}
          {currentFunc === 5? <Tile icon={Mantenimento} {...propsFunzioni.tile6}/>:null}
          {currentFunc === 6? <Tile icon={Cottura_a_bassa_temperatura} {...propsFunzioni.tile7}/>:null}
          {currentFunc === 7? <Tile icon={Lievitazione} {...propsFunzioni.tile8}/>:null}
          {currentFunc === 8? <Tile icon={Cioccolato} {...propsFunzioni.tile9}/>:null}
        </div>

        <div className='row1Funzioni'>
          <span onClick={()=>setCurrentFunc(5)}><Bottone {...propsFunzioni.btn6} pieno={currentFunc === 5? 0:1} icona={Mantenimento}/></span>          

          <span onClick={()=>setCurrentFunc(6)}><Bottone {...propsFunzioni.btn7} pieno={currentFunc === 6? 0:1} icona={Cottura_a_bassa_temperatura}/></span>

          <span onClick={()=>setCurrentFunc(7)}><Bottone {...propsFunzioni.btn8} pieno={currentFunc === 7? 0:1} icona={Lievitazione}/></span>

          <span onClick={()=>setCurrentFunc(8)}><Bottone {...propsFunzioni.btn9} pieno={currentFunc === 8? 0:1} icona={Cioccolato}/></span>
        </div>
        <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
      </div>
    );
  }
}

export default FunzioniIta;