import './MyA.css'
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/blu.svg'

import Immagine from './../../risorse/Immagini/MyA.png'

function MyA(props) {

  if(window.innerWidth>850){    
    return (
      <div className='MyA'>
        <div className='flexMyA'>        
          <div className='textMyA'>
            <div className='titoloSezione'>{props.title}</div>
            <div className='testo18'>{props.txt}</div>
          </div>  
          <div className='imgMyA' ><img src={Immagine}/></div>
        </div>
        <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
      </div>
    );
  }else{
    return (
      <div className='MyA'>
        <div className='flexMyA'>    
          <div className='titoloSezione'>{props.title}</div>
          <div className='imgMyA' ><img src={Immagine}/></div>
          <div className='testo18'>{props.txt}</div>
        </div>
        <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
      </div>
    );
  }
}

export default MyA;