import './gamma.css'
import Tile from './tile';

import MF180 from './../../risorse/Immagini/MF180.2.png'
import MF250_MF350 from './../../risorse/Immagini/MF250.2-MF350.2_2T.png'
import { ReactComponent as X } from './../../risorse/Icone/x/blu.svg'


import { ReactComponent as Freccia } from './../../risorse/Icone/icona_freccetta_azzurra_destra.svg'


import { useState } from 'react';


//import dei testi
import propsGamma from './../props/ger/propsGamma'


function GammaIta(props) {
  
  const [clicked, setClicked] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState();

  return (
    <div className='gamma'>
      <span className='titoloSezione titoloGamma'>{propsGamma.sectionTitle}</span>
      {clicked?<X onClick={()=>setClicked(0)} className="xGwp"/>:null}
      <div className='flexTileGamma'>

        {!clicked || selectedDevice === 0 ?<Tile {...propsGamma.tile1}         
          img={MF180}
          setClicked={setClicked}
          clicked={clicked}
          setSelectedDevice={setSelectedDevice}/>:null}

        {!clicked || selectedDevice === 1 ?<Tile {...propsGamma.tile2}
          img={MF250_MF350}
          setClicked={setClicked}
          clicked={clicked}
          setSelectedDevice={setSelectedDevice}/>:null}

        {!clicked || selectedDevice === 2 ?<Tile {...propsGamma.tile3}
          img={MF250_MF350}
          setClicked={setClicked}
          clicked={clicked}
          setSelectedDevice={setSelectedDevice}/>:null}
      </div>
    </div>
  );
}

export default GammaIta;