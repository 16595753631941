import './sostenibilita.css';
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/bianca.svg'

function Sostenibilita(props) {
    return (
      <div className='sostenibilita'>
        <div className='bannerSostenibilita'>
          <div className='titoloSezione'>{props.sectionTitle}</div>
          <div className='righettaSostenibilità'/>
          <div className='testo24 boxTestoSostenibilita'>{props.txt}</div>
        </div>
        <div className='scrollArrowContainerSost' onClick={props.arrowClick}>
          <span className='txtScrollArrow'>{props.arrowTxt}</span>
          <ScrollArrow className="scrollArrow arrowSostenibilità"/>
        </div>
      </div>
    );
  }
  
  export default Sostenibilita;