import { useState } from 'react';
import './vantaggi.css'

function FrameVantaggi(props) {

  const load = () => {}

  const addStyle = (key) => {

    var style;

    style=props.id===key?{"backgroundColor":"white","color":"#004514"}:{"color":"white"};    

    return style;
  }

  return (
    <div className='frameVantaggi' onLoad={()=>load()} style={{"backgroundImage": `url(${props.img})`}}>
      <div className={props.id<2?'boxVantaggi diffBGBoxVantaggi':'boxVantaggi normalBGBoxVantaggi'}>

        <div className={props.id<2?'titoloDescVantaggi diffBGBothVantaggi':'titoloDescVantaggi normalBGBothVantaggi'}>
          <div className='titoletto'>{props.title}</div>
          <div className='testo18'>{props.desc}</div>
        </div> 

        <div className={props.id<2?'flexCerchiVantaggi normalBGBothVantaggi':'flexCerchiVantaggi normalBGBothVantaggi'}>
          <div className='numeriCerchiVantaggi' style={addStyle(0)} onClick={()=>props.selected(0)}>1</div>
          <div className='numeriCerchiVantaggi' style={addStyle(1)} onClick={()=>props.selected(1)}>2</div>
          <div className='numeriCerchiVantaggi' style={addStyle(2)} onClick={()=>props.selected(2)}>3</div>
          <div className='numeriCerchiVantaggi' style={addStyle(3)} onClick={()=>props.selected(3)}>4</div>         
        </div>

         
      </div>      
    </div>
  );
}

export default FrameVantaggi;