import './performance.css'
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/blu.svg'

import { ReactComponent as Bollino } from './../../risorse/Icone/Cerchi/bollino.svg'
import { ReactComponent as Cerchio25 } from './../../risorse/Icone/Cerchi/25.svg'
import { ReactComponent as Cerchio50 } from './../../risorse/Icone/Cerchi/50.svg'
import Tile from './tile'

function Performance(props) {
  return (
    <div className='performance'>
      <div className='flexPerformance'>
        <span className='titoloSezione titoloPerformance'>{props.title}</span>
        <div className='flexTilesPerformance'>
          <Tile title={props.subtitle[0]} txt={props.txt[0]} svg={Cerchio25}/>
          <Tile title={props.subtitle[1]} txt={props.txt[1]} svg={Bollino}/>
          <Tile title={props.subtitle[2]} txt={props.txt[2]} svg={Cerchio50}/>
        </div>
      </div>
      
      <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
    </div>
  );
}

export default Performance;