const propsNavbar = {
    linkHome : "https://www.irinoxprofessional.com/ita/",

    titoli : [
        {
            txt : "SETTORI",
            link : "",
        },
        {
            txt : "PRODOTTI",
            link : "",
        },
        {
            txt : "VIDEO",
            link : "https://www.irinoxprofessional.com/ita/video/fresh-stories",
        },
        {
            txt : "I NOSTRI CLIENTI",
            link : "https://www.irinoxprofessional.com/ita/mondo-irinox/i-nostri-clienti/",
        },
        {
            txt : "ACADEMY",
            link : "https://www.irinoxprofessional.com/ita/academy/",
        }
    ],

    lingua: "IT",

    propsSettori : {
        tile1 : {
            last : 0,
            txt : "Raffreddamento e surgelazione",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/raffreddamento-e-surgelazione"
        },
        tile2 : {
            last : 0,
            txt : "I vantaggi di Irinox",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/i-vantaggi-di-irinox"
        },
        tile3 : {
            last : 0,
            txt : "Pasticceria",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/pasticceria"
        },
        tile4 : {
            last : 0,
            txt : "Gelateria",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/gelateria"
        },
        tile5 : {
            last : 0,
            txt : "Ristorazione",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/ristorazione"
        },
        tile6 : {
            last : 0,
            txt : "Panificazione",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/panificazione"
        },
        tile7 : {
            last : 0,
            txt : "Industria e catering",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/industria-e-catering"
        },
        tile8 : {
            last : 1,
            txt : "Perchè inserire un abbattitore nella tua cucina?",
            link : "https://www.irinoxprofessional.com/ita/perche-irinox/perch%C3%A8-inserire-un-abbattitore-nella-tua-cucina"
        },
    },

    propsProdotti : {
        tile1 : {
            link : [
                "https://www.irinoxprofessional.com/ita/prodotti/abbattitori/",
                "https://www.irinoxprofessional.com/ita/prodotti/abbattitori/multifresh%C2%AE-next-1/",
                "https://www.irinoxprofessional.com/ita/prodotti/abbattitori/easyfresh%C2%AE-next-1/"
            ],
            vediTutti : "Vedi tutti",
            gamma : [
                "MultiFresh® Next",
                "EasyFresh® Next"
            ],
            serie : "ABBATTITORI"
        },
        tile2 : {
            link : [
                "https://www.irinoxprofessional.com/ita/prodotti/abbattitori-roll-in/",
                "https://www.irinoxprofessional.com/ita/prodotti/abbattitori-roll-in/multifresh%C2%AE-mya-1/",
                ""
            ],
            vediTutti : "Vedi tutti",
            gamma : [
                "MultiFresh® MyA",
                ""
            ],
            serie : "ABBATTITORI ROLL-IN"
        },
        tile3 : {
            link : [
                "https://www.irinoxprofessional.com/ita/prodotti/conservatori/",
                "https://www.irinoxprofessional.com/ita/prodotti/conservatori/cp-next/",
                "https://www.irinoxprofessional.com/ita/prodotti/conservatori/ck-next/"
            ],
            vediTutti : "Vedi tutti",
            gamma : [
                "CP Next",
                "CK Next"
            ],
            serie : "CONSERVATORI"
        }
    },

    propsBurger : {
        voci : [
            {
                txt : "Video",
                link : "https://www.irinoxprofessional.com/ita/video/fresh-stories"
            },
            {
                txt : "I nostri clienti",
                link : "https://www.irinoxprofessional.com/ita/mondo-irinox/i-nostri-clienti/"
            },
            {
                txt : "Academy",
                link : "https://www.irinoxprofessional.com/ita/academy/"
            },
            {
                txt : "Azienda",
                link : "https://www.irinoxprofessional.com/ita/azienda"
            },
            {
                txt : "Area Download",
                link : "https://www.irinoxprofessional.com/ita/area-download/"
            },
            {
                txt : "FreshCloud",
                link : "https://www.irinoxprofessional.com/ita/fresh-cloud"
            },
            {
                txt : "Applicazioni",
                link : "https://www.irinoxprofessional.com/ita/applicazioni/"
            },
            {
                txt : "Contattaci",
                link : "https://www.irinoxprofessional.com/ita/contatti"
            },
        ],

        dropdown : [
            {
                grande : 1,
                txtTitle : "Settori",                     
                miniLinkTxt : [
                    "Raffreddamento e surgelazione",
                    "I vantaggi di Irinox",
                    "Pasticceria",
                    "Gelateria",
                    "Ristorazione",
                    "Panificazione",
                    "Industria e catering",
                    "Perchè inserire un abbattitore nella tua cucina?"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/ita/perche-irinox/raffreddamento-e-surgelazione",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/i-vantaggi-di-irinox",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/pasticceria",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/gelateria",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/ristorazione",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/panificazione",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/industria-e-catering",
                    "https://www.irinoxprofessional.com/ita/perche-irinox/perch%C3%A8-inserire-un-abbattitore-nella-tua-cucina"
                ]
            },
            {
                grande : 1,
                txtTitle : "Prodotti",                        
                miniLinkTxt : [
                    "Abbattitori",
                    "Abbattitori roll-in",
                    "Conservatori"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/ita/prodotti/abbattitori/",
                    "https://www.irinoxprofessional.com/ita/prodotti/abbattitori-roll-in/",
                    "https://www.irinoxprofessional.com/ita/prodotti/conservatori/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Assistenza tecnica",                        
                miniLinkTxt : [
                    "Centro Assistenza Tecnica Italia",
                    "Centro Assistenza Tecnica Estero",
                    "After Sales Tools"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/ita/cat-italia",
                    "https://www.irinoxprofessional.com/ita/cat-estero",
                    "https://irinox.aftersalestools.com/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Mondo Irinox",                        
                miniLinkTxt : [
                    "News",
                    "Eventi",
                    "Partnership",
                    "I nostri clienti"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/ita/mondo-irinox/news/",
                    "https://www.irinoxprofessional.com/ita/mondo-irinox/eventi/",
                    "https://www.irinoxprofessional.com/ita/mondo-irinox/partnership/",
                    "https://www.irinoxprofessional.com/ita/mondo-irinox/i-nostri-clienti/"
                ]
            }
        ],
        
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    },

    propsLingua : {
        txtLingua : [
            "Italiano",
            "English",
            "Español",
            "Français",
            "Deutsche"
        ],
        txtBtn: [
            "ANNULLA",
            "IMPOSTA LINGUA"
        ]
    }
}

export default propsNavbar;