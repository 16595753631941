import ImmagineGwp from './../../../risorse/Immagini/Grafico GWP ita.png'

const propsLanding = {
    propsContattaci : {
        txt : "Contattaci",
        link : "https://www.irinoxprofessional.com/ita/contatti"
    },

    propsSostenibilita : {
        sectionTitle : ["Multi",<span style={{"fontStyle":"italic"}}>Fresh® </span>,"MyA CO",<span className='subscript50'>2</span>],
        arrowTxt : "Scopri di più",
        txt : ["La prima gamma di abbattitori di temperatura multifunzione a carrello a CO",<sub>2</sub>,".", <br></br> ,"Maggiore velocità di abbattimento, minori consumi energetici, impatto ambientale zero."]
    },

    propsGwp : {
        title : "Una soluzione rispettosa dell'ambiente.",
        description : ["Il gas CO",<sub>2</sub>,"  è un refrigerante naturale, sicuro per l’ambiente con un GWP pari a 1. Il potenziale di riscaldamento globale (GWP) di un gas è la capacità relativa di un gas di intrappolare il calore all'interno dell'atmosfera nel tempo rispetto alla CO",<sub>2</sub>,". Quanto più alto è il valore dell'indice GWP, tanto maggiore è l'incidenza di un gas sul riscaldamento globale."],
        grafico1 : {
            img: "",
            title: "title",
            txt: ""
        },
        grafico2 : {
            img: ImmagineGwp,
            title: "Global Warming Potential (GWP)",
            txt: ["Il Global Warming Potential (GWP) esprime il contributo all'effetto serra di un gas, relativamente all'effetto della CO",<sub>2</sub>,", il cui potenziale di riferimento è pari a 1. Quindi ciascuno dei gas rilasciati in atmosfera ha un proprio GWP che descrive il proprio effetto climalterante. Tutti i gas, con i propri GWP, assieme alla CO",<sub>2</sub>," rilasciata che presenta GWP 1 per definizione, formano l’emissione complessiva di CO",<sub>2</sub>," eq."]
        },
        grafico3 : {
            img: "",
            title: "title",
            txt: ""
        },
    },

    propsVantaggi : {
        titleTxt : [
            ["Perché scegliere il gas CO",<sub>2</sub>,"?"],
            ["Una scelta responsabile."],
            "Facile manutenzione.",
            "Minor impatto, consumi ridotti."
        ],
        descTxt : [
            ["Il gas CO",<sub>2</sub>," è un refrigerante naturale, sicuro per l’ambiente. Non infiammabile, atossico e il 20% più efficiente."],
            ["L’uso della CO",<sub>2</sub>," come gas refrigerante nelle varie applicazioni sta sempre più aumentando, dovuto al fatto che gli attuali gas refrigeranti HFC, come e l’R404A, dovranno essere dismessi in modo graduale a breve termine."],
            ["Oltre ad essere un gas facilmente reperibile, i costi di manutenzione sono notevolmente inferiori soprattutto in caso di perdite."],
            ["Grazie alle sue caratteristiche fisiche, il gas CO",<sub>2</sub>," assorbe più calore rispetto ai tradizionali gas presenti sul mercato, come l’R404A e l’R452A. Inoltre, consente l’utilizzo di compressori che consumano meno energia elettrica."]
        ]
    },
    
    propsMyA : {
        title: ["MultiFresh® MyA CO",<sub>2</sub>],
        txt : [
            "L'inimitabile assistente multifunzione."
            ,<br></br>,<br></br>,
            "È la prima gamma di abbattitori di temperature multifunzione a carrello con gas refrigerante R744, più conosciuto come CO",<sub>2</sub>,"  . È l’abbattitore di temperatura multifunzione. L'abbattitore di temperatura MultiFresh® MyA CO",<sub>2</sub>,"  è disponibile solo in versione PLUS multifunzione che comprende non solo le funzioni fredde ma anche quelle calde ed è capace di gestire cicli di funzionamento con temperature dai +85°C a -35°C."
            ,<br></br>,<br></br>,
            "Premendo un tasto puoi raffreddare, surgelare, lievitare, scongelare, rigenerare, pastorizzare e cuocere a bassa temperatura."
            ,<br></br>,<br></br>,
            "Tutte le funzioni sono state accuratamente testate in collaborazione con i più prestigiosi professionisti, della ristorazione. Per ogni alimento e per ogni processo produttivo abbiamo stabilito la corretta temperatura, la giusta ventilazione e il grado di umidità ideale per una perfetta istantanea della tua qualità."
            ,<br></br>,<br></br>,
            "I nostri abbattitori rapidi di temperatura garantiscono la più veloce estrazione del calore, anche in presenza di prodotti bollenti, senza danneggiare in alcun modo l’alimento nel rispetto dell’ambiente."
        ]
    },

    propsPerformance : {
        title : ["MultiFresh® MyA CO",<sub>2</sub>," è efficienza e performance"],
        subtitle : [
            "Più rapido",
            "Sostenibilità",
            "Efficienza"
        ],
        txt : [
            ["Il gas CO",<sub>2</sub>," non solo ti aiuta a diventare più ecologico, ma è estremamente efficace offrendoti prestazioni migliorate."],
            ["Lavoriamo ogni giorno per prenderci cura del nostro pianeta. Con gli abbattitori IRINOX a CO",<sub>2</sub>," anche tu avrai la possibilità di contribuire a questo cambiamento."],
            ["Scegliere MultiFresh® a CO",<sub>2</sub>," significa anche ridurre i consumi energetici. Fino al 50% rispetto alla gamma precedente."]
        ]
    },

    propsComposizione : {
        title : ["Le soluzioni IRINOX"],
        subtitle : [
            "Evaporatori",
            "Unità condensante",
            "Chiller (optional)",
            "Il display"
        ],
        desc : [
            ["I ventilatori dell’evaporatore sono completamente in alluminio, con bilanciatura delle pale in rame e/o in acciaio inox, garantiscono igiene e facile pulizia. "],
            ["L’unità condensante IRINOX è dotata di box elettrico completo di tutti i dispositivi di sicurezza ed è interamente costruita e collaudata al 100% da IRINOX nei propri laboratori insieme alla cella."],
            ["È possibile fornire l’acqua di raffreddamento all’unità condensante a CO",<sub>2</sub>," collegando un chiller dedicato o sfruttando i chiller centralizzati HVAC già esistenti. In alternativa è possibile collegare un impianto geotermico esistente."],
            ["MyA è l’interfaccia touchscreen degli abbattitori multifunzione MultiFresh® che rende l’utilizzo della macchina semplice e intuitivo.",<br></br> ,"Le sue icone ti accompagnano attraverso le numerose funzioni con un’ampia scelta di cicli studiati per rispettare le qualità organolettiche di ogni alimento. ",<br></br>,"Solo con MyA hai la massima personalizzazione: potrai variare i parametri di ogni ciclo con semplicità e creare il processo ideale per ciascun prodotto. "]
        ],
        txtChiller: "Per completare l'installazione è necessario collegare una tra queste tre componenti.",
        btnTxt: [
            "CHILLER DEDICATO",
            "CHILLER PERSONALIZZATO",
            "IMPIANTO GEOTERMICO"
        ]
    },

    propsFooter : {
        txt : ["Irinox nasce nel 1989 in Italia, a Corbanese ed è riconosciuto in tutto il mondo come lo specialista degli abbattitori e surgelatori rapidi di temperatura. La produzione, Made in Italy, è interamente realizzata presso i propri stabilimenti, in un’area di 15.000 m",<sup>2</sup>," alle porte di Treviso."],
        address : ["Via Caduti nei Lager, 1",<br></br>,"31015 Conegliano (TV) - Italy",<br></br>,"Tel. +39 0438 2020",<br></br>,"Fax +39 0438 2023"],
        map : ["MOSTRA SULLA MAPPA","https://www.google.com/maps/place/Irinox+SpA+-+Business+Unit+Professional/@45.9555145,12.312648,15z/data=!4m5!3m4!1s0x0:0x9caf1aa567fe6666!8m2!3d45.9555145!4d12.312648"],
        assistenza : [
            "ASSISTENZA",
            "https://www.irinoxprofessional.com/ita/richiedi-assistenza"
        ],
        service : [
            "SERVICE",
            "https://www.irinoxprofessional.com/ita/service"
        ],
        contattaci : [
            "CONTATTACI",
            "https://www.irinoxprofessional.com/ita/contatti"
        ],
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    }
}

export default propsLanding;