const fWeight = {"fontWeight":"700"};

const propsGamma = {
    sectionTitle: ["Das MultiFresh® MyA CO",<sub>2</sub>,"-Sortiment"],

    tile1: {        
        title : ["MF 180.2 CO",<sub>2</sub>," PLUS"],
        scopri : "Finde mehr heraus",
        id : 0,
        description : [
            <span style={fWeight}>Leistung Pro Zyklus: </span>,"180kg",<br></br>,
            <span style={fWeight}>Kapazität: </span>,"1x20 (GN 2/1 o 600x800 mm)",<br></br>,"2x20 (GN 1/1 o 600x400 mm)",<br></br>,
            <span style={fWeight}>Abmessungen: </span>,"1600x1425x2350 mm",<br></br>,
            <span style={fWeight}>Plus-Version: </span>,"10,4 kW 15,3A 400 V-50Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Verflüssigereinheit UC180CO<sub>2</sub> - 1200x1200x1170 mm: </span>,"400 v-50 Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Maximal absorbierte leistung UC180CO<sub>2</sub>: </span>,"12.07 kW",<br></br>,
            <span style={fWeight}>Maximal aufgenommener strom UC180CO<sub>2</sub>: </span>,"22.59 A",<br></br>
        ]
    },
    tile2 : {
        title : ["MF 250.2 CO",<sub>2</sub>," PLUS"],
        scopri : "Finde mehr heraus",
        id : 1,
        description : [
            <span style={fWeight}>Leistung Pro Zyklus: </span>,"250kg",<br></br>,
            <span style={fWeight}>Kapazität: </span>,"1x20 (GN 2/1 o 600x800 mm)",<br></br>,"2x20 (GN 1/1 o 600x400 mm)",<br></br>,
            <span style={fWeight}>Abmessungen: </span>,"1600x1425x2350 mm",<br></br>,
            <span style={fWeight}>Plus-Version: </span>,"10,4 kW 15,3A 400 V-50Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Verflüssigereinheit UC250CO<sub>2</sub> - 1200x1200x1170 mm: </span>,"400 v-50 Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Maximal absorbierte leistung UC250CO<sub>2</sub>: </span>,"14.47 kW",<br></br>,
            <span style={fWeight}>Maximal aufgenommener strom UC250CO<sub>2</sub>: </span>,"26.08 A",<br></br>
        ]
    },

    tile3 : {
        title : ["MF 350.2 2T CO",<sub>2</sub>," PLUS"],
        scopri : "Finde mehr heraus",
        id : 2,
        description : [
            <span style={fWeight}>Leistung Pro Zyklus: </span>,"350kg",<br></br>,
            <span style={fWeight}>Kapazität: </span>,"2x20 (GN 2/1 o 600x800 mm)",<br></br>,"4x20 (GN 1/1 o 600x400 mm)",<br></br>,
            <span style={fWeight}>Abmessungen: </span>,"1600x2755x2450 mm",<br></br>,
            <span style={fWeight}>Plus-Version: </span>,"20,2 kW 30,2A 400 V-50Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Verflüssigereinheit UC350CO<sub>2</sub> - 1200x1200x1170 mm: </span>,"400 v-50 Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Maximal absorbierte leistung UC350CO<sub>2</sub>: </span>,"23.37 kW",<br></br>,
            <span style={fWeight}>Maximal aufgenommener strom UC350CO<sub>2</sub>: </span>,"41.08 A",<br></br>
        ]
    }
}

export default propsGamma;