const propsFunzioni = {
    tile1 : {
        title : "Raffreddamento",
        txt : [" L'abbattitore di temperatura MultiFresh® MyA raffredda rapidamente a +3°C da qualsiasi temperatura anche bollente, rispettando consistenza, umidità e proprietà nutritive di ogni alimento. "]
    },

    tile2 : {
        title : "Surgelazione",
        txt : ["Con l'abbattitore di temperatura MultiFresh® MyA scegli la temperatura, l’ora e lo spessore in cui trovare i tuoi alimenti scongelati e pronti all’uso. Gestire lo scongelamento  significa rallentare il processo di proliferazione batterica e mantenere intatta la struttura degli alimenti."]
    },

    tile3 : {
        title : "Scongelamento",
        txt : ["Con l'abbattitore di temperatura MultiFresh® MyA puoi rigenerare tutti i tuoi piatti impostando la temperatura e l’ora in cui avere il prodotto caldo, pronto ad essere servito, passando in automatico da +3°C o -18°C alla rigenerazione fino alla temperatura desiderata, senza danneggiarlo."]
    },

    tile4 : {
        title : "Rigenerazione",
        txt : ["Con MultiFresh® MyA puoi rigenerare tutti i tuoi piatti impostando la temperatura e l’ora in cui avere il prodotto caldo, pronto ad essere servito, passando in automatico da +3°C o -18°C alla rigenerazione fino alla temperatura desiderata, senza danneggiarlo."]
    },

    tile5 : {
        title : "Pastorizzazione",
        txt : ["L'abbattitore di temperatura MultiFresh® MyA pastorizza e raffredda (+3°C) oppure pastorizza e surgela (-18°C) gli alimenti riducendo la contaminazione batterica ed aumentando la loro durata."]
    },

    tile6 : {
        title : "Mantenimento",
        txt : ["Con l'abbattitore di temperatura MultiFresh® MYA tutti gli alimenti possono essere mantenuti senza alterazioni alla temperatura desiderata, sia positiva che negativa."]
    },

    tile7 : {
        title : "Cottura a bassa temperatura",
        txt : ["L'abbattitore di temperatura MultiFresh® MyA cuoce a bassa temperatura carne o pesce, passando in automatico in raffreddamento o in surgelazione direttamente dopo la cottura. I principali vantaggi di questo tipo di cottura consistono nell’esaltazione del gusto e in una maggior uniformità di cottura."]
    },

    tile8 : {
        title : "Lievitazione",
        txt : ["L'abbattitore di temperatura MultiFresh® MyA propone cicli per una lievitazione naturale, senza sbalzi eccessivi di temperatura, controllando l’umidità presente in camera e programmando il momento in cui trovare i prodotti perfettamente lievitati."]
    },

    tile9 : {
        title : "Cioccolato",
        txt : ["MultiFresh® MyA soddisfa le esigenze di pasticceri e cioccolatieri, con cicli dedicati al cioccolato, che ne mantengono il corretto livello di umidità e temperatura per una lunga e corretta conservazione."]
    },
    
}

export default propsFunzioni;