import './App.css';

import LandingIta from "./components/landing/landingIta"
import LandingEng from "./components/landing/landingEng"
import LandingSpa from "./components/landing/landingSpa"
import LandingFra from "./components/landing/landingFra"
import LandingGer from "./components/landing/landingGer"

import react, { useEffect, useState } from 'react';

function App() {
  const lang = ["ita","eng","spa","fra","ger"]

  const [lingua,setLingua] = useState(0)  

  useEffect(()=>{

    if(lingua===0){
      document.title="MultiFresh a CO2: l'abbattitore a carrello sostenibile"
      document.head.children[4].content = "La prima gamma di abbattitori di temperatura multifunzione a carrello a CO2.\nMaggiore velocità di abbattimento, minori consumi energetici, impatto ambientale zero."
    }
    else if(lingua===1){
      document.title = "MultiFresh CO2: the sustainable trolley blast chiller"
      document.head.children[4].content = "The first range of CO2 roll-in multifunction blast chillers.\nGreater blast chilling speed, lower energy consumption, zero environmental impact."
    }
    else if(lingua===2){
      document.title = "MultiFresh CO2: el abatidor de temperatura de carro sostenible"
      document.head.children[4].content = "La primera gama de abatidores multifunción de carro de CO2.\nMayor velocidad de abatimiento, menor consumo energético, cero impacto ambiental."
    }
    else if(lingua===3){
      document.title = "MultiFresh CO2: La cellule de refroidissement rapide à chariot durable"
      document.head.children[4].content = "La première gamme de cellules de refroidissement multifonctions à chariot CO2.\n Vitesse de refroidissement plus rapide, consommation d'énergie réduite, impact environnemental nul."
    }
    else if(lingua===4){
      document.title = "MultiFresh CO2: Der umweltfreundliche Roll-in Schnellkühler und Schockfroster"
      document.head.children[4].content = "Die erste Reihe von Roll-inn Multifunktions-Schnellkühlern mit CO2.\n Höhere Schnellkühlgeschwindigkeit, geringerer Energieverbrauch, keine Umweltbelastung."
    }

  },[lingua])

  if (lang[lingua] === "ita"){
    return (
      <div className='app'>
        <LandingIta lingua={lingua} setLingua={setLingua}/>
      </div>
    );
  }else if(lang[lingua] === "eng"){
    return (
      <div className='app'>
        <LandingEng lingua={lingua} setLingua={setLingua}/>
      </div>
    );
  }else if(lang[lingua] === "spa"){
    return (
      <div className='app'>
        <LandingSpa lingua={lingua} setLingua={setLingua}/>
      </div>
    );
  }else if(lang[lingua] === "fra"){
    return (
      <div className='app'>
        <LandingFra lingua={lingua} setLingua={setLingua}/>
      </div>
    );
  }else if(lang[lingua] === "ger"){
    return (
      <div className='app'>
        <LandingGer lingua={lingua} setLingua={setLingua}/>
      </div>
    );
  }
}

export default App;
