const propsFunzioni = {
    tile1 : {
        title : "Enfriamento",
        txt : ["El abatidor MultiFresh® MyA enfría rápidamente a + 3ºC desde cualquier temperatura,  incluso hirviendo, respetando la consistencia, humedad y propiedades nutricionales de cada alimento."]
    },

    tile2 : {
        title : "Congelación",
        txt : ["Con el abatidor MultiFresh® MyA eliges la temperatura, el tiempo y el grosor en el que encontrar tus alimentos descongelados y listos para usar. Gestionar la descongelación significa ralentizar el proceso de proliferación bacteriana y mantener intacta la estructura de los alimentos."]
    },

    tile3 : {
        title : "Déscongélation",
        txt : ["Con el abatidor de temperatura MultiFresh® MyA tu puedes regenerar todos tus platos configurando la temperatura y el tiempo en el que tener el producto caliente, listo para servir, pasando automáticamente de +3°C o -18°C a regeneración hasta la temperatura deseada, sin dañarlo."]
    },

    tile4 : {
        title : "Regeneración",
        txt : ["Con MultiFresh® MyA puedes regenerar todos tus platos configurando la temperatura y el tiempo en el que tener el producto caliente, listo para servir, pasando automáticamente de +3°C o -18°C a regeneración hasta la temperatura deseada, sin dañarlo."]
    },

    tile5 : {
        title : "Pasteurización",
        txt : ["El abatidor MultiFresh® MyA pasteuriza y enfría (+3°C) o pasteuriza y congela (-18°C) los alimentos, reduciendo la contaminación bacteriana y aumentando su vida útil."]
    },

    tile6 : {
        title : "Mantenimiento",
        txt : ["Con el abatidor MultiFresh® MYA todos los alimentos pueden conservarse sin alteración a la temperatura deseada, tanto positiva como negativa."]
    },

    tile7 : {
        title : "Cocción a baja temperatura",
        txt : ["MultiFresh® cocina a baja temperatura carne o pescado, pasando al modo enfriamiento o congelación en automático, directamente después de la cocción. Las ventajas principales de este tipo de cocción consisten en la exaltación del gusto y en una cocción más uniforme."]
    },

    tile8 : {
        title : "Fermentación",
        txt : ["MF® propone ciclos de fermentación natural, sin oscilaciones excesivas de temperatura, controlando la humedad presente en la cámara y programando el momento en el que los productos estarán perfectamente fermentados."]
    },

    tile9 : {
        title : "Chocolate",
        txt : ["MultiFresh® satisface las necesidades de pasteleros y chocolateros, con ciclos dedicados al chocolate, que mantienen el nivel correcto de humedad y temperatura, para una conservación prolongada y correcta."]
    },
    
}

export default propsFunzioni;