import { useState } from 'react';
import './gwp.css';

function GraficoGWP(props) {

    const [hover,setHover] = useState(0)

    return (
      <div className={props.clicked && window.innerWidth>1500?'graficoGwp graficoCliccatoGwp':'graficoGwp'}
        onMouseEnter={window.innerWidth>1500?
            ()=>!props.clicked?setHover(1):null
          :null
        }
        onMouseLeave={window.innerWidth>1500?
            ()=>!props.clicked?setHover(0):null
          :null
        }
        onClick={window.innerWidth>1500?
          ()=>{
            props.setClicked(props.clicked? 0:1)
            setHover(0)
            props.setSelectedChart(props.id)
          }:null
        }
        style={hover?{"opacity":"0.6"}:{"":""}}>
          <img className='immagineGraficoGwp' src={props.img}/>
          <div className='titleDescGwp'>
              <div className='titoletto'>{props.title}</div>
              {props.clicked||window.innerWidth<=1500?<div className='testo18'>{props.description}</div>:null}
          </div>
      </div>
    );
  }
  
  export default GraficoGWP;