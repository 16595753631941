import react from "react";
import './settori.css'

function TileSettori(props) {
    return (
        <a href={props.link} target="_blank">
            <div className={props.last?"tileSettori lastTileSettori":"tileSettori"}>
                {props.txt}
            </div>
        </a>
    );
}
  
export default TileSettori;