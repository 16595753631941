import './vantaggi.css'
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/bianca.svg'
import FrameVantaggi from './Frame';

import Immagine1 from './../../risorse/Immagini/Vantaggi_1.jpg'
import Immagine2 from './../../risorse/Immagini/Vantaggi_2.jpg'
import Immagine3 from './../../risorse/Immagini/Vantaggi_3.jpg'
import Immagine4 from './../../risorse/Immagini/Vantaggi_4.jpg'

import { useState } from 'react';

function Vantaggi(props) {

  const [selected,setSelected] = useState(0)
  
  return (
    <div className='vantaggi'>
      {selected===0?      
        <FrameVantaggi selected={setSelected} id={0} title={props.titleTxt[0]} desc={props.descTxt[0]} img={Immagine1}/>
        :selected===1?
          <FrameVantaggi selected={setSelected} id={1} title={props.titleTxt[1]} desc={props.descTxt[1]} img={Immagine2}/>
          :selected===2?
            <FrameVantaggi selected={setSelected} id={2} title={props.titleTxt[2]} desc={props.descTxt[2]} img={Immagine3}/>
            :selected===3?
              <FrameVantaggi selected={setSelected} id={3} title={props.titleTxt[3]} desc={props.descTxt[3]} img={Immagine4}/>
              :null
      }

      <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrow"/>
    </div>
  );
}

export default Vantaggi;