import './footer.css'
import { ReactComponent as FbBianco } from './icone/fb_bianca.svg'
import { ReactComponent as InBianco } from './icone/in_bianca.svg'
import { ReactComponent as IgBianco } from './icone/ig_bianca.svg'
import { ReactComponent as YtBianco } from './icone/yt_bianca.svg'
import { ReactComponent as FbColorato } from './icone/fb_colorata.svg'
import { ReactComponent as InColorato } from './icone/in_colorata.svg'
import { ReactComponent as IgColorato } from './icone/ig_colorata.svg'
import { ReactComponent as YtColorato } from './icone/yt_colorata.svg'

import { ReactComponent as Logo } from './icone/logo.svg'
import { ReactComponent as Contact } from './icone/contact.svg'
import { ReactComponent as Operator } from './icone/operator.svg'
import { ReactComponent as Tools } from './icone/tools.svg'

import { useState } from 'react'

const Footer = (props) => {
    
    const [hoverFb,setHoverFb] = useState(0)
    const [hoverIn,setHoverIn] = useState(0)
    const [hoverIg,setHoverIg] = useState(0)
    const [hoverYt,setHoverYt] = useState(0)

    return(
        <div className="footer">
            <div className='colFooter'>
                <a href='https://www.irinoxprofessional.com/' target='_blank'><Logo className="logoFooter"/></a>
                <div className='textFooter withMaxFooter'>
                    {props.txt}
                </div>
                <span className='txtBoldFooter'>IRINOX S.p.A.</span>
                <div className='textFooter'>
                    {props.address}
                </div>
                <a href={props.map[1]} target='_blank' className='buttonFooter'>
                    {props.map[0]}
                </a>
            </div>
            <div className='colFooter col2Footer'>       
                <a href={props.assistenza[1]} target="_blank" className='buttonFooter btnDxFooter'>
                    {props.assistenza[0]}
                    <Operator className="iconeFooter"/>
                </a>
                <a href={props.service[1]} target="_blank" className='buttonFooter btnDxFooter'>
                    {props.service[0]}
                    <Tools className="iconeFooter"/>
                </a>
                <a href={props.contattaci[1]} target="_blank" className='buttonFooter btnDxFooter'>
                    {props.contattaci[0]}
                    <Contact className="iconeFooter"/>
                </a>
                <div className="rowIconeSocialFooter">
                    {hoverFb?
                        <a title="IconaFB" href={props.fb} target="_blank" onMouseLeave={()=>setHoverFb(0)}><FbColorato className="iconeSocialFooter"/></a>
                    :
                        <a title="IconaFB" href={props.fb} target="_blank" onMouseEnter={()=>setHoverFb(1)}><FbBianco className="iconeSocialFooter"/></a>
                    }

                    {hoverIn?
                        <a href={props.in} target="_blank" onMouseLeave={()=>setHoverIn(0)}><InColorato className="iconeSocialFooter"/></a>
                    :
                        <a href={props.in} target="_blank" onMouseEnter={()=>setHoverIn(1)}><InBianco className="iconeSocialFooter"/></a>
                    }

                    {hoverIg?
                        <a href={props.ig} target="_blank" onMouseLeave={()=>setHoverIg(0)}><IgColorato className="iconeSocialFooter"/></a>
                    :
                        <a href={props.ig} target="_blank" onMouseEnter={()=>setHoverIg(1)}><IgBianco className="iconeSocialFooter"/></a>
                    }

                    {hoverYt?
                        <a href={props.yt} target="_blank" onMouseLeave={()=>setHoverYt(0)}><YtColorato className="iconeSocialFooter"/></a>
                    :
                        <a href={props.yt} target="_blank" onMouseEnter={()=>setHoverYt(1)}><YtBianco className="iconeSocialFooter"/></a>
                    }
                </div>
            </div>

        </div>
    );
}

export default Footer;