const propsNavbar = {
    linkHome : "https://www.irinoxprofessional.com/eng/",

    titoli : [
        {
            txt : "INDUSTRY",
            link : "",
        },
        {
            txt : "PRODUCTS",
            link : "",
        },
        {
            txt : "VIDEO",
            link : "https://www.irinoxprofessional.com/eng/video/fresh-stories",
        },
        {
            txt : "OUR CUSTOMERS",
            link : "https://www.irinoxprofessional.com/eng/irinox-world/",
        },
        {
            txt : "ACADEMY",
            link : "https://www.irinoxprofessional.com/eng/academy/",
        }
    ],

    lingua: "EN",

    propsSettori : {
        tile1 : {
            last : 0,
            txt : "Blast chill and shock freeze",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/blast-chill-and-shock-freeze"
        },
        tile2 : {
            last : 0,
            txt : "The advantages of Irinox",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/the-advantages-of-irinox"
        },
        tile3 : {
            last : 0,
            txt : "Pastry",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/pastry"
        },
        tile4 : {
            last : 0,
            txt : "Ice-cream",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/ice-cream"
        },
        tile5 : {
            last : 0,
            txt : "Gastronomy",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/gastronomy"
        },
        tile6 : {
            last : 0,
            txt : "Bread making",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/bread-making"
        },
        tile7 : {
            last : 0,
            txt : "Industry and catering",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/industry-and-catering"
        },
        tile8 : {
            last : 1,
            txt : "Six reasons why having an Irinox blast chiller in your kitchen",
            link : "https://www.irinoxprofessional.com/eng/why-irinox/six-reasons-why-x-an-irinox-blast-chiller-in-your-kitchen"
        },
    },

    propsProdotti : {
        tile1 : {
            link : [
                "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers/",
                "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers/multifresh%C2%AE-next-1/",
                "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers/easyfresh%C2%AE-next-1/"
            ],
            vediTutti : "View all",
            gamma : [
                "MultiFresh® Next",
                "EasyFresh® Next"
            ],
            serie : "BLAST CHILLERS | SHOCK FREEZERS"
        },
        tile2 : {
            link : [
                "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers-roll-in/",
                "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers-roll-in/multifresh%C2%AE-mya-1/",
                ""
            ],
            vediTutti : "View all",
            gamma : [
                "MultiFresh® MyA",
                ""
            ],
            serie : "BLAST CHILLERS | SHOCK FREEZERS ROLL-IN"
        },
        tile3 : {
            link : [
                "https://www.irinoxprofessional.com/eng/products/holding-cabinets/",
                "https://www.irinoxprofessional.com/eng/products/holding-cabinets/cp-next/",
                "https://www.irinoxprofessional.com/eng/products/holding-cabinets/ck-next/"
            ],
            vediTutti : "View all",
            gamma : [
                "CP Next",
                "CK Next"
            ],
            serie : "HOLDING CABINETS"
        }
    },

    propsBurger : {
        voci : [
            {
                txt : "Video",
                link : "https://www.irinoxprofessional.com/eng/video/fresh-stories"
            },
            {
                txt : "Our customers",
                link : "https://www.irinoxprofessional.com/eng/irinox-world/"
            },
            {
                txt : "Academy",
                link : "https://www.irinoxprofessional.com/eng/academy/"
            },
            {
                txt : "Company",
                link : "https://www.irinoxprofessional.com/eng/company"
            },
            {
                txt : "Download Area",
                link : "https://www.irinoxprofessional.com/eng/area-download/"
            },
            {
                txt : "FreshCloud",
                link : "https://www.irinoxprofessional.com/eng/fresh-cloud"
            },
            {
                txt : "Applications",
                link : "https://www.irinoxprofessional.com/eng/applications/"
            },
            {
                txt : "Contact us",
                link : "https://www.irinoxprofessional.com/eng/contacts"
            },
        ],

        dropdown : [
            {
                grande : 1,
                txtTitle : "Industry",                     
                miniLinkTxt : [
                    "Blast chill and shock freeze",
                    "The advantages of Irinox",
                    "Pastry",
                    "Ice-cream",
                    "Gastronomy",
                    "Bread making",
                    "Industry and catering",
                    "Six reasons why having an Irinox blast chiller in your kitchen"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/eng/why-irinox/blast-chill-and-shock-freeze",
                    "https://www.irinoxprofessional.com/eng/why-irinox/the-advantages-of-irinox",
                    "https://www.irinoxprofessional.com/eng/why-irinox/pastry",
                    "https://www.irinoxprofessional.com/eng/why-irinox/ice-cream",
                    "https://www.irinoxprofessional.com/eng/why-irinox/gastronomy",
                    "https://www.irinoxprofessional.com/eng/why-irinox/bread-making",
                    "https://www.irinoxprofessional.com/eng/why-irinox/industry-and-catering",
                    "https://www.irinoxprofessional.com/eng/why-irinox/six-reasons-why-x-an-irinox-blast-chiller-in-your-kitchen"
                ]
            },
            {
                grande : 1,
                txtTitle : "Products",                        
                miniLinkTxt : [
                    "Blast Chillers | Shock Freezers",
                    "Blast Chillers | Shock Freezers Roll-in",
                    "Holding cabinets"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers/",
                    "https://www.irinoxprofessional.com/eng/products/blast-chillers-shock-freezers-roll-in/",
                    "https://www.irinoxprofessional.com/eng/products/holding-cabinets/"
                ]
            },
            {
                grande : 0,
                txtTitle : "After-sales service",                        
                miniLinkTxt : [
                    "Technical Support Centre in Italy",
                    "Technical Support Centre Abroad",
                    "After Sales Tools"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/eng/tsc-in-italy",
                    "https://www.irinoxprofessional.com/eng/tsc-abroad",
                    "https://irinox.aftersalestools.com/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Irinox World",                        
                miniLinkTxt : [
                    "News",
                    "Partnership",
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/eng/irinox-world/news/",
                    "https://www.irinoxprofessional.com/eng/irinox-world/partnership/"
                ]
            }
        ],
        
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    },

    propsLingua : {
        txtLingua : [
            "Italiano",
            "English",
            "Español",
            "Français",
            "Deutsche"
        ],
        txtBtn: [
            "CANCEL",
            "SET LANGUAGE"
        ]
    }
}

export default propsNavbar;