import react from "react";

function Tile(props) {
    return (
        <div className={props.lingua === props.id ? 'tileLingua selezione' : 'tileLingua'}
            onClick={()=>{props.setLingua(props.id)}}>
            <props.icona className="bandiera"/>
            <div>{props.txt}</div>
        </div>
    );
}
    
export default Tile;