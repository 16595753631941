const Tile = (props) => {

    if(window.innerWidth>670){        
        return(
            <div className="tileUnicaComp">
                <img src={props.img} className="imgTileUnicaComp"/>
                <div className="colTileUC">
                    <div className="titoletto">
                        {props.title}
                    </div>
                    <div className="testo18">
                        {props.desc}
                    </div>
                </div>
            </div>
        );
    }else{
        return(
            <div className="tileUnicaComp">            
                <div className="titoletto">
                    {props.title}
                </div>
                <img src={props.img} className="imgTileUnicaComp"/>
                <div className="testo18">
                    {props.desc}
                </div>
            </div>
        );
    }
}

export default Tile;