const TerzaTile = (props) => {
    return(
        <div className="terzaTile">
            <div className="testo18 txtChiller">
                {props.txt}
            </div>
            
            <div className="btnRigaChiller">
                <Button txt={props.btnTxt[0]}/>
                <Button txt={props.btnTxt[1]}/>
                <Button txt={props.btnTxt[2]}/>
            </div>

            <div className="testo18 txtChiller">
                {props.desc}
            </div>
        </div>
    );
}

export default TerzaTile;

const Button = ({txt}) => {
    return(
        <div className="btnChiller">
            {txt}
        </div>
    );
}