const fWeight = {"fontWeight":"700"};

const propsGamma = {
    sectionTitle: ["La gamme MultiFresh® MyA CO",<sub>2</sub>],

    tile1: {        
        title : ["MF 180.2 CO",<sub>2</sub>," PLUS"],
        scopri : "En savoir plus",
        id : 0,
        description : [
            <span style={fWeight}>Rendement par cycle: </span>,"180kg",<br></br>,
            <span style={fWeight}>Capacités: </span>,"1x20 (GN 2/1 o 600x800 mm)",<br></br>,"2x20 (GN 1/1 o 600x400 mm)",<br></br>,
            <span style={fWeight}>Dimensions: </span>,"1600x1425x2350 mm",<br></br>,
            <span style={fWeight}>Plus version: </span>,"10,4 kW 15,3A 400 V-50Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Unité de condensation UC180CO<sub>2</sub> - 1200x1200x1170 mm: </span>,"400 v-50 Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Puissance maximale absorbée UC180CO<sub>2</sub>: </span>,"12.07 kW",<br></br>,
            <span style={fWeight}>Courant absorbé maximum UC180CO<sub>2</sub>: </span>,"22.59 A",<br></br>
        ]
    },
    tile2 : {
        title : ["MF 250.2 CO",<sub>2</sub>," PLUS"],
        scopri : "En savoir plus",
        id : 1,
        description : [
            <span style={fWeight}>Rendement par cycle: </span>,"250kg",<br></br>,
            <span style={fWeight}>Capacités: </span>,"1x20 (GN 2/1 o 600x800 mm)",<br></br>,"2x20 (GN 1/1 o 600x400 mm)",<br></br>,
            <span style={fWeight}>Dimensions: </span>,"1600x1425x2350 mm",<br></br>,
            <span style={fWeight}>Plus version: </span>,"10,4 kW 15,3A 400 V-50Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Unité de condensation UC250CO<sub>2</sub> - 1200x1200x1170 mm: </span>,"400 v-50 Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Puissance maximale absorbée UC250CO<sub>2</sub>: </span>,"14.47 kW",<br></br>,
            <span style={fWeight}>Courant absorbé maximum UC250CO<sub>2</sub>: </span>,"26.08 A",<br></br>
        ]
    },

    tile3 : {
        title : ["MF 350.2 2T CO",<sub>2</sub>," PLUS"],
        scopri : "En savoir plus",
        id : 2,
        description : [
            <span style={fWeight}>Rendement par cycle: </span>,"350kg",<br></br>,
            <span style={fWeight}>Capacités: </span>,"2x20 (GN 2/1 o 600x800 mm)",<br></br>,"4x20 (GN 1/1 o 600x400 mm)",<br></br>,
            <span style={fWeight}>Dimensions: </span>,"1600x2755x2450 mm",<br></br>,
            <span style={fWeight}>Plus version: </span>,"20,2 kW 30,2A 400 V-50Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Unité de condensation UC350CO<sub>2</sub> - 1200x1200x1170 mm: </span>,"400 v-50 Hz (3P+N+PE)",<br></br>,
            <span style={fWeight}>Puissance maximale absorbée UC350CO<sub>2</sub>: </span>,"23.37 kW",<br></br>,
            <span style={fWeight}>Courant absorbé maximum UC350CO<sub>2</sub>: </span>,"41.08 A",<br></br>
        ]
    }
}

export default propsGamma;