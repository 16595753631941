import react from "react";
import './prodotti.css'
import { ReactComponent as Freccia } from './../../../risorse/Icone/icona_freccetta_azzurra_destra.svg'

function TileProdotti(props) {
    return (
        <div className="tileProdotti">
            <img src={props.img}/>
            <div className="titolettiProdotti">
                <a href={props.link[0]} target="_blank"><div className="serieProdotti">{props.serie}</div></a>
                <div className="gammaProdotti">
                    <Freccia/>
                    <a href={props.link[1]} target="_blank"><span className="txtGamma">{props.gamma[0]}</span></a>
                </div>
                {!props.gamma[1]?
                    <div className="gammaProdotti invisibleProdotti">
                        <Freccia/>
                        <span className="txtGamma">{props.gamma[0]}</span>
                   </div> 
                :
                    <div className="gammaProdotti">
                        <Freccia/>
                        <a href={props.link[2]} target="_blank"><span className="txtGamma">{props.gamma[1]}</span></a>
                    </div>
                }
                <div className="barraProdotti"/>
                <a href={props.link[0]} target="_blank"><div className="vediTuttiProdotti">{props.vediTutti}</div></a>
            </div>
        </div>
    );
}
  
export default TileProdotti;