const propsNavbar = {
    linkHome : "https://www.irinoxprofessional.com/deu/",

    titoli : [
        {
            txt : "BRANCHEN",
            link : "",
        },
        {
            txt : "PRODUKTE",
            link : "",
        },
        {
            txt : "VIDEO",
            link : "https://www.irinoxprofessional.com/deu/video/fresh-stories",
        },
        {
            txt : "UNSERE KUNDEN",
            link : "https://www.irinoxprofessional.com/deu/irinox-welt/",
        },
        {
            txt : "ACADEMY",
            link : "https://www.irinoxprofessional.com/deu/academy/",
        }
    ],

    lingua: "DE",

    propsSettori : {
        tile1 : {
            last : 0,
            txt : "Schnellkühlen und Schockfrosten",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/schnellk%C3%BChlen-und-schockfrosten"
        },
        tile2 : {
            last : 0,
            txt : "Ihre Vorteile",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/ihre-vorteile"
        },
        tile3 : {
            last : 0,
            txt : "Konditorei",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/konditorei"
        },
        tile4 : {
            last : 0,
            txt : "Speiseeisbereitung",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/speiseeisbereitung"
        },
        tile5 : {
            last : 0,
            txt : "Gastronomie",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/gastronomie"
        },
        tile6 : {
            last : 0,
            txt : "Brotbereitung",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/brotbereitung"
        },
        tile7 : {
            last : 0,
            txt : "Gemeinschaftsverpflegung",
            link : "https://www.irinoxprofessional.com/deu/warum-irinox/gemeinschaftsverpflegung"
        },
    },

    propsProdotti : {
        tile1 : {
            link : [
                "https://www.irinoxprofessional.com/deu/produkte/schnellk%C3%BChler-schockfroster/",
                "https://www.irinoxprofessional.com/deu/produkte/schnellk%C3%BChler-schockfroster/multifresh%C2%AE-next-1/",
                "https://www.irinoxprofessional.com/deu/produkte/schnellk%C3%BChler-schockfroster/easyfresh%C2%AE-next-1/"
            ],
            vediTutti : "Alles sehen",
            gamma : [
                "MultiFresh® Next",
                "EasyFresh® Next"
            ],
            serie : "SCHNELLKÜHLER | SCHOCKFROSTER"
        },
        tile2 : {
            link : [
                "https://www.irinoxprofessional.com/deu/produkte/roll-in/",
                "https://www.irinoxprofessional.com/deu/produkte/roll-in/multifresh%C2%AE-mya-1/",
                ""
            ],
            vediTutti : "Alles sehen",
            gamma : [
                "MultiFresh® MyA",
                ""
            ],
            serie : "ROLL-IN"
        },
        tile3 : {
            link : [
                "https://www.irinoxprofessional.com/deu/produkte/k%C3%BChllagerschr%C3%A4nke-tiefk%C3%BChlschr%C3%A4nke/",
                "https://www.irinoxprofessional.com/deu/produkte/k%C3%BChllagerschr%C3%A4nke-tiefk%C3%BChlschr%C3%A4nke/cp-next/",
                "https://www.irinoxprofessional.com/deu/produkte/k%C3%BChllagerschr%C3%A4nke-tiefk%C3%BChlschr%C3%A4nke/ck-next/"
            ],
            vediTutti : "Alles sehen",
            gamma : [
                "CP Next",
                "CK Next"
            ],
            serie : "KÜHLLAGERSCHRÄNKE | TIEFKÜHLSCHRÄNKE"
        }
    },

    propsBurger : {
        voci : [
            {
                txt : "Video",
                link : "https://www.irinoxprofessional.com/deu/video/fresh-stories"
            },
            {
                txt : "Unsere Kunden",
                link : "https://www.irinoxprofessional.com/deu/irinox-welt/"
            },
            {
                txt : "Academy",
                link : "https://www.irinoxprofessional.com/deu/academy/"
            },
            {
                txt : "Unternehmen",
                link : "https://www.irinoxprofessional.com/deu/unternehmen"
            },
            {
                txt : "Download-Bereich",
                link : "https://www.irinoxprofessional.com/deu/area-download/"
            },
            {
                txt : "FreshCloud",
                link : "https://www.irinoxprofessional.com/deu/fresh-cloud"
            },
            {
                txt : "Anwendungen",
                link : "https://www.irinoxprofessional.com/deu/anwendungen/"
            },
            {
                txt : "Kontakte",
                link : "https://www.irinoxprofessional.com/deu/kontakte"
            },
        ],

        dropdown : [
            {
                grande : 1,
                txtTitle : "Branchen",                     
                miniLinkTxt : [
                    "Schnellkühlen und Schockfrosten",
                    "Ihre Vorteile",
                    "Konditorei",
                    "Speiseeisbereitung",
                    "Gastronomie",
                    "Brotbereitung",
                    "Gemeinschaftsverpflegung"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/deu/warum-irinox/schnellk%C3%BChlen-und-schockfrosten",
                    "https://www.irinoxprofessional.com/deu/warum-irinox/ihre-vorteile",
                    "https://www.irinoxprofessional.com/deu/warum-irinox/konditorei",
                    "https://www.irinoxprofessional.com/deu/warum-irinox/speiseeisbereitung",
                    "https://www.irinoxprofessional.com/deu/warum-irinox/gastronomie",
                    "https://www.irinoxprofessional.com/deu/warum-irinox/brotbereitung",
                    "https://www.irinoxprofessional.com/deu/warum-irinox/gemeinschaftsverpflegung"
                ]
            },
            {
                grande : 1,
                txtTitle : "Produkte",                        
                miniLinkTxt : [
                    "Schnellkühler | Schockfroster",
                    "Roll-in",
                    "Kühllagerschränke | Tiefkühlschränke"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/deu/produkte/schnellk%C3%BChler-schockfroster/",
                    "https://www.irinoxprofessional.com/deu/produkte/roll-in/",
                    "https://www.irinoxprofessional.com/deu/produkte/k%C3%BChllagerschr%C3%A4nke-tiefk%C3%BChlschr%C3%A4nke/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Technische Unterstützung",                        
                miniLinkTxt : [
                    "Technisches Kundendienstzentrum Italien",
                    "Technisches Kundendienstzentrum Ausland",
                    "After Sales Tools"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/deu/cat-italien",
                    "https://www.irinoxprofessional.com/deu/cat-ausland",
                    "https://irinox.aftersalestools.com/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Irinox-Welt",                        
                miniLinkTxt : [
                    "News",
                    "Partner"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/deu/irinox-welt/news/",
                    "https://www.irinoxprofessional.com/deu/irinox-welt/partner/",
                ]
            }
        ],
        
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    },

    propsLingua : {
        txtLingua : [
            "Italiano",
            "English",
            "Español",
            "Français",
            "Deutsche"
        ],
        txtBtn: [
            "ABBRECHEN",
            "SPRACHE EINSTELLEN"
        ]
    }
}

export default propsNavbar;