import './composizione.css'

import { ReactComponent as X } from './../../risorse/Icone/x/blu.svg'

const Tile = (props) => {
    return(
        <div className='tileComposizione'>
            <X onClick={()=>props.setTile(0)} className="xGwp"/>
            <div className='titoletto'>{props.title}</div>
            <img src={props.img} className="imgTileComposizione"/>
            <div className='testo18'>{props.desc}</div>
        </div>
    );
}

export default Tile;