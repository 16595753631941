import ImmagineGwp from './../../../risorse/Immagini/Grafico GWP eng.png'

const propsLanding = {
    propsContattaci : {
        txt : "Contact us",
        link : "https://www.irinoxprofessional.com/eng/contacts"
    },

    propsSostenibilita : {
        sectionTitle : ["Multi",<span style={{"fontStyle":"italic"}}>Fresh® </span>,"MyA CO",<span className='subscript50'>2</span>],
        arrowTxt : "Find out more",
        txt : ["The first range of CO",<sub>2</sub>," roll-in multifunction blast chillers.", <br></br> ,"Greater blast chilling speed, lower energy consumption, zero environmental impact."]
    },

    propsGwp : {
        title : "An environmentally friendly solution.",
        description : ["CO",<sub>2</sub>," gas is a natural, environmentally safe refrigerant with a GWP of 1. The global warming potential (GWP) of a gas is the relative ability of a gas to trap heat within the atmosphere over the time compared to CO",<sub>2</sub>,". The higher the GWP index value, the greater the impact of a gas on global warming."],
        grafico1 : {
            img: "",
            title: "title",
            txt: ""
        },
        grafico2 : {
            img: ImmagineGwp,
            title: "Global Warming Potential (GWP)",
            txt: ["Global warming potential (GWP) is the heat absorbed by any greenhouse gas in the atmosphere, as a multiple of the heat that would be absorbed by the same mass of carbon dioxide (CO",<sub>2</sub>,"). GWP is 1 for CO",<sub>2</sub>,".Most of the current gases used in commercial refrigeration such as R404A AND R452A will soon be no longer permitted by law. R744 or CO",<sub>2</sub>," gas is used to create sustainable and low-cost solutions that reduce the environmental impact. CO",<sub>2</sub>,", in fact, is a natural gas that has no impact on global warming with a GWP equal to 1, thousands of times lower than previous generation gases."]
        },
        grafico3 : {
            img: "",
            title: "title",
            txt: ""
        },
    },

    propsVantaggi : {
        titleTxt : [
            ["Why choose CO",<sub>2</sub>," gas?"],
            ["A responsible choice."],
            "Easy maintenance.",
            "Less impact, reduced consumption."
        ],
        descTxt : [
            ["CO",<sub>2</sub>," gas is a natural refrigerant, safe for the environment. Non-flammable, non-toxic and 20% more efficient."],
            ["The use of CO",<sub>2</sub>," as a refrigerant gas in various applications is increasingly increasing, due to the fact that current HFC refrigerant gases, such as R404A, will have to be phased out gradually in the short term."],
            ["In addition to being a readily available gas, the costs of maintenance are significantly less especially in case of leaks. "],
            ["Thanks to its physical characteristics, CO",<sub>2</sub>," gas absorbs more heat than traditional gases on the market, such as R404A and R452A. In addition, it allows the use of compressors that consume less electricity."]
        ]
    },
    
    propsMyA : {
        title: ["MultiFresh® MyA CO",<sub>2</sub>],
        txt : [
            "The inimitable multifunctional assistant."
            ,<br></br>,<br></br>,
            "It is the first range of trolley-mounted multifunction blast chillers with R744 refrigerant gas, better known as CO2. It is the multifunction blast chiller. The MultiFresh® MyA CO2 blast chiller is only available in the PLUS multifunction version which includes not only the cold functions but also the hot ones and is capable of managing operating cycles with temperatures from + 85 ° C to -35 ° C."
            ,<br></br>,<br></br>,
            "At the push of a button you can cool, freeze, rise, defrost, regenerate, pasteurize and cook at a low temperature."
            ,<br></br>,<br></br>,
            "All functions have been carefully tested in collaboration with the most prestigious catering professionals. For each food and for each production process we have established the correct temperature, the right ventilation and the ideal humidity level for a perfect snapshot of your quality."
            ,<br></br>,<br></br>,
            "Our rapid blast chillers guarantee the fastest extraction of heat, even in the presence of hot products, without damaging the food in any way while respecting the environment. "
        ]
    },

    propsPerformance : {
        title : ["MultiFresh® MyA CO",<sub>2</sub>," is efficiency and performance"],
        subtitle : [
            "Faster",
            "Sustainability",
            "Efficiency"
        ],
        txt : [
            ["CO",<sub>2</sub>," gas not only helps you become greener, it is extremely effective by offering you improved performance. "],
            ["We work every day to take care of our planet. With the IRINOX CO",<sub>2</sub>," blast chillers you too will have the opportunity to contribute to this change. "],
            ["Choosing CO",<sub>2</sub>," MultiFresh® also means reducing energy consumption. Up to 50% compared to the previous range. "]
        ]
    },

    propsComposizione : {
        title : ["IRINOX solutions"],
        subtitle : [
            "Evaporators",
            "Condensing unit",
            "Chiller (optional)",
            "Display"
        ],
        desc : [
            ["The evaporator fans are completely made of aluminum, with balancing of the copper and / or stainless steel blades, guaranteeing hygiene and easy cleaning."],
            ["The IRINOX condensing unit is equipped with an electrical box complete with all safety devices and is entirely built and 100% tested by IRINOX in its laboratories together with the cell. "],
            ["It is possible to supply cooling water to the unit CO",<sub>2</sub>," condensing unit by connecting a dedicated chiller or using existing centralized HVAC chillers. Alternatively, it is possible to connect an existing geothermal system. "],
            ["MyA is the new touch screen interface for MultiFresh® multi-function blast chillers that makes using the machine even easier and more intuitive. The icons guide you through the numerous functions with a wide choice of cycles designed to preserve the fragrance, flavour and aroma of each item. Full customization is possible only with MyA: it’s easy to vary the parameters for each cycle and create the ideal process for every product."]
        ],
        txtChiller: "One of these three components needs be connected to complete the installation.",
        btnTxt: [
            "DEDICATED CHILLER",
            "CUSTOMIZED CHILLER",
            "GEOTHERMAL SYSTEM"
        ]
    },

    propsFooter : {
        txt : ["Irinox was founded in 1989 in Corbanese, Italy. It is renowned worldwide as a specialist in blast chillers and shock freezers. The production, Made in Italy, is completely carried out in our plants, in a 9000 m",<sup>2</sup>," area at the outskirts of Treviso."],
        address : ["Via Caduti nei Lager, 1",<br></br>,"31015 Conegliano (TV) - Italy",<br></br>,"Tel. +39 0438 2020",<br></br>,"Fax +39 0438 2023"],
        map : ["SHOW ON THE MAP","https://www.google.com/maps/place/Irinox+SpA+-+Business+Unit+Professional/@45.9555145,12.312648,15z/data=!4m5!3m4!1s0x0:0x9caf1aa567fe6666!8m2!3d45.9555145!4d12.312648"],
        assistenza : [
            "ASSISTANCE",
            "https://www.irinoxprofessional.com/eng/ask-support"
        ],
        service : [
            "SERVICE",
            "https://www.irinoxprofessional.com/eng/service"
        ],
        contattaci : [
            "CONTACT US",
            "https://www.irinoxprofessional.com/eng/contacts"
        ],
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    }
}

export default propsLanding;