import react, { useEffect, useState } from "react";
import SetLingua from "./scelta lingua/lingua"
import PopUpSettori from "./pop up settori/settori"
import PopUpProdotti from "./pop up prodotti/prodotti";
import BurgerAperto from "./burger aperto/burger";
import './navbar.css'
import { ReactComponent as IconaLingua} from "./../../risorse/Icone/icona_lingue.svg"

import { ReactComponent as LogoBlu } from './../../risorse/Icone/logo_blu.svg'
import { ReactComponent as LogoBianco } from './../../risorse/Icone/logo_bianco.svg'

import { Spin as Hamburger } from 'hamburger-react'

//import dei testi
import propsNavbar from './../props/ger/propsNavbar'

var lastScroll=0;

function Navbarita(props) {
  const [apriBurger, setApriBurger] = useState(0)

  const [popLingua, setPopLingua] = useState(0)
  const [popSettori, setPopSettori] = useState(0)
  const [popProdotti, setPopProdotti] = useState(0)

  const [showNav, setShowNav] = useState(1)

  const print = () =>{
    if (!apriBurger&&window.innerWidth>1023){
      if(window.innerWidth<=1600){      
        if(lastScroll<window.scrollY&&window.scrollY>=70){
          setShowNav(0)
        }else if(lastScroll>window.scrollY){
          setShowNav(1)
        }
      }
      else{
        if(lastScroll<window.scrollY&&window.scrollY>=100){
          setShowNav(0)
        }else if(lastScroll>window.scrollY){
          setShowNav(1)
        }
      }
      lastScroll=window.scrollY
    }
  }

  useEffect(()=>{
    if(apriBurger){
      setShowNav(1)
      document.body.classList.add('bodyStopOverflow');
    }else{
      document.body.classList.remove('bodyStopOverflow');
    }
  }, [apriBurger])

  useEffect(()=>{
    if(popLingua){
      document.body.classList.add('bodyStopOverflow');
    }else{
      document.body.classList.remove('bodyStopOverflow');
    }
  }, [popLingua])

  useEffect(()=>{
    window.addEventListener("scroll",print)
  }, [])

  return (
    <div>
      <div className={showNav||apriBurger? "contenitoreComponentiNavbar":"contenitoreComponentiNavbar hideThings"}>
        <div className={!apriBurger?'navbar':'navbar navbarBlu'}>
            <a className="linkLogoNav" href={propsNavbar.linkHome} target={"_blank"}>
              {!apriBurger?
                <LogoBlu className="logoNavbar"/>
              : <LogoBianco className="logoNavbar"/>}
            </a>
            {!apriBurger?
              <div className="titoliNav">
                <div className="sezioneSettori">
                  <div className={popSettori ? "titoloNav titoloPopNav tilteHovering" :"titoloNav titoloPopNav"}
                    onMouseEnter={()=>setPopSettori(1)} onMouseLeave={()=>setPopSettori(0)}>
                    {propsNavbar.titoli[0].txt}
                  </div>
                  {popSettori?
                    <div className="showPopUp"
                      onMouseEnter={()=>setPopSettori(1)} onMouseLeave={()=>setPopSettori(0)}>
                      <PopUpSettori {...propsNavbar.propsSettori}/>
                    </div>
                  :null}                     
                </div>
                
                <div className="sezioneProdotti">
                  <div className={popProdotti ? "titoloNav titoloPopNav tilteHovering" :"titoloNav titoloPopNav"}
                    onMouseEnter={()=>setPopProdotti(1)} onMouseLeave={()=>setPopProdotti(0)}>
                    {propsNavbar.titoli[1].txt}
                  </div>
                  {popProdotti?
                    <div className="showPopUp"
                      onMouseEnter={()=>setPopProdotti(1)} onMouseLeave={()=>setPopProdotti(0)}>
                      <PopUpProdotti {...propsNavbar.propsProdotti}/>
                    </div>
                  :null}
                </div>
                <a href={propsNavbar.titoli[2].link} target={"_blank"} className="titoloNav">
                {propsNavbar.titoli[2].txt}
                </a>
                <a href={propsNavbar.titoli[3].link} target={"_blank"} className="titoloNav">
                  {propsNavbar.titoli[3].txt}
                </a>
                <a href={propsNavbar.titoli[4].link} target={"_blank"} className="titoloNav">
                  {propsNavbar.titoli[4].txt}
                </a>
              </div>
            :null}
            <div className="destraNav">
              {!apriBurger?
                <div className="linguaNav" onClick={()=>setPopLingua(1)}>
                  {propsNavbar.lingua}
                  <IconaLingua className="iconaLinguaNav"/>
                </div>
              :null}
              <div className="burgerMenu">
                <div onClick={()=>{
                  apriBurger === 0 ? setApriBurger(1):setApriBurger(0)
                }}>
                  <Hamburger size={25}/>
                </div>
              </div>
            </div>
        </div>   
      </div>
      {apriBurger? <BurgerAperto {...propsNavbar.propsBurger}/>:null} 
      
      {popLingua&&!apriBurger ? <SetLingua popLingua={popLingua} setPopLingua={setPopLingua} lingua={props.lingua} setLingua={props.setLingua} {...propsNavbar.propsLingua}/> : null}        
    </div>
  );
}
  
 export default Navbarita;