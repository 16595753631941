import './contattaci.css';
import { ReactComponent as Mailbox } from './../../risorse/Icone/contattaci.svg'

function Contattaci(props) {
    return (
        <a href={props.link} target="_blank">
            <div className='contattaci'>
                <Mailbox className="svgContattaci"/>
                {props.txt}
            </div>
        </a>
    );
  }
  
  export default Contattaci;