const propsNavbar = {
    linkHome : "https://www.irinoxprofessional.com/spa/",

    titoli : [
        {
            txt : "SECTOR",
            link : "",
        },
        {
            txt : "PRODUCTOS",
            link : "",
        },
        {
            txt : "VIDEO",
            link : "https://www.irinoxprofessional.com/spa/video/fresh-stories",
        },
        {
            txt : "NUESTROS CLIENTES",
            link : "https://www.irinoxprofessional.com/spa/mundo-irinox/nuestros-clientes/",
        },
        {
            txt : "ACADEMY",
            link : "https://www.irinoxprofessional.com/spa/academy/",
        }
    ],

    lingua: "ES",

    propsSettori : {
        tile1 : {
            last : 0,
            txt : "Refrigeración y congelación",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/refrigeraci%C3%B3n-y-congelaci%C3%B3n"
        },
        tile2 : {
            last : 0,
            txt : "Los beneficios de Irinox",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/los-beneficios-de-irinox"
        },
        tile3 : {
            last : 0,
            txt : "Pastelería ",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/pasteler%C3%ADa"
        },
        tile4 : {
            last : 0,
            txt : "Heladería",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/helader%C3%ADa"
        },
        tile5 : {
            last : 0,
            txt : "Restauración",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/restauraci%C3%B3n"
        },
        tile6 : {
            last : 0,
            txt : "Panificación",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/panificaci%C3%B3n"
        },
        tile7 : {
            last : 0,
            txt : "Industria y catering",
            link : "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/industria-y-catering"
        },
    },

    propsProdotti : {
        tile1 : {
            link : [
                "https://www.irinoxprofessional.com/spa/productos/abatidores-r%C3%A1pidos/",
                "https://www.irinoxprofessional.com/spa/productos/abatidores-r%C3%A1pidos/multifresh%C2%AE-next-1/",
                "https://www.irinoxprofessional.com/spa/productos/abatidores-r%C3%A1pidos/easyfresh%C2%AE-next-1/"
            ],
            vediTutti : "Ver todo",
            gamma : [
                "MultiFresh® Next",
                "EasyFresh® Next"
            ],
            serie : "ABATIDORES RÁPIDOS"
        },
        tile2 : {
            link : [
                "https://www.irinoxprofessional.com/spa/productos/roll-in/",
                "https://www.irinoxprofessional.com/spa/productos/roll-in/multifresh%C2%AE-mya-1/",
                ""
            ],
            vediTutti : "Ver todo",
            gamma : [
                "MultiFresh® MyA",
                ""
            ],
            serie : "ROLL-IN"
        },
        tile3 : {
            link : [
                "https://www.irinoxprofessional.com/spa/productos/conservadores/",
                "https://www.irinoxprofessional.com/spa/productos/conservadores/cp-next/",
                "https://www.irinoxprofessional.com/spa/productos/conservadores/ck-next/"
            ],
            vediTutti : "Ver todo",
            gamma : [
                "CP Next",
                "CK Next"
            ],
            serie : "CONSERVADORES"
        }
    },

    propsBurger : {
        voci : [
            {
                txt : "Video",
                link : "https://www.irinoxprofessional.com/spa/video/fresh-stories"
            },
            {
                txt : "Nuestros clientes",
                link : "https://www.irinoxprofessional.com/spa/mundo-irinox/nuestros-clientes/"
            },
            {
                txt : "Academy",
                link : "https://www.irinoxprofessional.com/spa/academy/"
            },
            {
                txt : "Empresa",
                link : "https://www.irinoxprofessional.com/spa/empresa"
            },
            {
                txt : "Área de descarga",
                link : "https://www.irinoxprofessional.com/spa/area-download/"
            },
            {
                txt : "FreshCloud",
                link : "https://www.irinoxprofessional.com/spa/fresh-cloud"
            },
            {
                txt : "Aplicaciones",
                link : "https://www.irinoxprofessional.com/spa/aplicaciones/"
            },
            {
                txt : "Contáctanos",
                link : "https://www.irinoxprofessional.com/spa/contactos"
            },
        ],

        dropdown : [
            {
                grande : 1,
                txtTitle : "Sector",                     
                miniLinkTxt : [
                    "Refrigeración y congelación",
                    "Los beneficios de Irinox",
                    "Pastelería ",
                    "Heladería",
                    "Restauración",
                    "Panificación",
                    "Industria y catering"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/refrigeraci%C3%B3n-y-congelaci%C3%B3n",
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/los-beneficios-de-irinox",
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/pasteler%C3%ADa",
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/helader%C3%ADa",
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/restauraci%C3%B3n",
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/panificaci%C3%B3n",
                    "https://www.irinoxprofessional.com/spa/por-qu%C3%A9-irinox/industria-y-catering"
                ]
            },
            {
                grande : 1,
                txtTitle : "Productos",                        
                miniLinkTxt : [
                    "Abatidores rápidos",
                    "Roll-in",
                    "Conservadores"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/spa/productos/abatidores-r%C3%A1pidos/",
                    "https://www.irinoxprofessional.com/spa/productos/roll-in/",
                    "https://www.irinoxprofessional.com/spa/productos/conservadores/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Asistencia técnica",                        
                miniLinkTxt : [
                    "Centro de asistencia técnica en Italia",
                    "Centro de asistencia técnica en el extranjero",
                    "After Sales Tools"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/spa/cat-en-italia",
                    "https://www.irinoxprofessional.com/spa/cat-en-el-extranjero",
                    "https://irinox.aftersalestools.com/"
                ]
            },
            {
                grande : 0,
                txtTitle : "Mundo Irinox",                        
                miniLinkTxt : [
                    "Nuestros Clientes",
                    "Eventos",
                    "News",
                    "Partnership"
                ],
                miniLinkLnk : [
                    "https://www.irinoxprofessional.com/spa/mundo-irinox/nuestros-clientes/",
                    "https://www.irinoxprofessional.com/spa/mundo-irinox/eventos/",
                    "https://www.irinoxprofessional.com/spa/mundo-irinox/news/",
                    "https://www.irinoxprofessional.com/spa/mundo-irinox/partnership/"
                ]
            }
        ],
        
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    },

    propsLingua : {
        txtLingua : [
            "Italiano",
            "English",
            "Español",
            "Français",
            "Deutsche"
        ],
        txtBtn: [
            "CANCELAR",
            "ELEGIR LENGUAJE"
        ]
    }
}

export default propsNavbar;