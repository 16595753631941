import './gwp.css';
import { ReactComponent as ScrollArrow } from './../../risorse/Icone/icone frecce scroll/verde.svg'
import GraficoGWP from './graficoGWP';

import { ReactComponent as X } from './../../risorse/Icone/x/verde.svg'

import { useState } from 'react';

function GWP(props) {

  const [clicked,setClicked] = useState(0)
  const [selectedChart, setSelectedChart] = useState()


  return (
    <div className='gwp' style={clicked?{"padding":"20px 0 0 0"}:{"":""}}>
      {!clicked||window.innerWidth<=1500?
        <div className='spiegazioneGwp'>
          <span className='titoloSezione'>{props.title}</span>
          <span className='testo18'>{props.description}</span>
        </div>
      : <X onClick={()=>setClicked(0)} className="xGwp"/>
      }
      
      <div className='flexGraficiGwp' style={clicked?{"justifyContent":"center"}:{"":""}}>
        {/* {!clicked || selectedChart === 0 || window.innerWidth<=1500 ? <GraficoGWP setSelectedChart={setSelectedChart} id={0} clicked={clicked} setClicked={setClicked} img={props.grafico1.img} title={props.grafico1.title} description={props.grafico1.txt}/>:null} */}
        {!clicked || selectedChart === 1 || window.innerWidth<=1500 ? <GraficoGWP setSelectedChart={setSelectedChart} id={1} clicked={clicked} setClicked={setClicked} img={props.grafico2.img} title={props.grafico2.title} description={props.grafico2.txt}/>:null}
        {/* {!clicked || selectedChart === 2 || window.innerWidth<=1500 ? <GraficoGWP setSelectedChart={setSelectedChart} id={2} clicked={clicked} setClicked={setClicked} img={props.grafico3.img} title={props.grafico3.title} description={props.grafico3.txt}/>:null} */}
      </div>
      
      <ScrollArrow onClick={props.arrowClick} className="scrollArrow hideScrollArrowGwp"/>
    </div>
  );
}

export default GWP;