const propsFunzioni = {
    tile1 : {
        title : "Schnellkühlung",
        txt : ["Der Schnellkühler MultiFresh® MyA kühlt schnell auf + 3 ° C von jeder Temperatur, sogar kochend, unter Berücksichtigung der Konsistenz, Feuchtigkeit und Nährwerteigenschaften jedes Lebensmittels."]
    },

    tile2 : {
        title : "Schockfrosten",
        txt : ["Alle Funktionen wurden in Zusammenarbeit mit den renommiertesten Gastronomieprofis sorgfältig getestet. Für jedes Lebensmittel und für jeden Produktionsprozess haben wir die richtige Temperatur, die richtige Belüftung und die ideale Luftfeuchtigkeit für eine perfekte Momentaufnahme Ihrer Qualität ermittelt."]
    },

    tile3 : {
        title : "Auftauen",
        txt : ["Unsere Schnellkühler garantieren die schnellste Wärmeentnahme auch bei heißen Produkten, ohne die Lebensmittel in irgendeiner Weise zu beschädigen und gleichzeitig die Umwelt zu schonen."]
    },

    tile4 : {
        title : "Regenerieren",
        txt : ["Mit MultiFresh® MyA können Sie alle Ihre Gerichte regenerieren, indem Sie die Temperatur und Zeit einstellen, zu der das heiße Produkt servierfertig sein soll, und automatisch von +3 ° C oder -18 ° C auf Regeneration bis zur gewünschten Temperatur umschalten, ohne beschädigen."]
    },

    tile5 : {
        title : "Pasteurisierung",
        txt : ["Der Schnellkühler MultiFresh® MyA pasteurisiert und kühlt (+ 3 ° C) oder pasteurisiert und gefriert (-18 ° C) Lebensmittel, reduziert die bakterielle Kontamination und verlängert ihre Haltbarkeit."]
    },

    tile6 : {
        title : "Konservierung",
        txt : ["Alle mit dem Schnellkühler MultiFresh® MYA die Lebensmittel können unverändert auf der gewünschten Temperatur gehalten werden, sowohl positiv als auch negativ. "]
    },

    tile7 : {
        title : "Niedertemperaturgaren",
        txt : ["Der Schnellkühler MultiFresh® MyA kocht a Niedertemperatur-Fleisch oder Fisch, automatisches Umschalten auf Kühlen oder Gefrieren direkt nach dem Garen. Die Hauptvorteile dieser Art des Garens bestehen in der Verbesserung des Geschmacks und der größeren Einheitlichkeit des Garens."]
    },

    tile8 : {
        title : "Gärung",
        txt : ["MultiFresh® bietet Zyklen für eine natürliche Gärung, ohne übermäßige Temperaturschwankungen. Man kann die im Innenraum vorhandene Feuchtigkeit kontrollieren und programmieren, zu welchem Zeitpunkt man die perfekt gegärten Teiglinge vorfinden möchte"]
    },

    tile9 : {
        title : "Schokolade",
        txt : ["MultiFresh® befriedigt alle Bedürfnisse der Patisseriers, Konditoren und Chocolatiers die spezifischen Schokoladenzyklen sorgen dafür, dass die für eine lange, korrekte Konservierung erforderlichen Feuchtigkeitswerte beibehalten werden."]
    },
    
}

export default propsFunzioni;