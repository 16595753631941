import react from "react";
import './prodotti.css'
import TileProdotti from "./tileProdotti";
import Immagine1 from './../../../risorse/Immagini/menù_a_tendina_1.png'
import Immagine2 from './../../../risorse/Immagini/menù_a_tendina_2.png'
import Immagine3 from './../../../risorse/Immagini/menù_a_tendina_3.png'

function PopUpProdotti(props) {
    return (
        <div className="popUpProdotti">
            <TileProdotti img={Immagine1} {...props.tile1}/>

            <TileProdotti img={Immagine2} {...props.tile2}/>

            <TileProdotti img={Immagine3} {...props.tile3}/>           
        </div>
    );
}
  
export default PopUpProdotti;