import ImmagineGwp from './../../../risorse/Immagini/Grafico GWP spa.png'

const propsLanding = {
    propsContattaci : {
        txt : "Contáctanos",
        link : "https://www.irinoxprofessional.com/spa/contactos"
    },

    propsSostenibilita : {
        sectionTitle : ["Multi",<span style={{"fontStyle":"italic"}}>Fresh® </span>,"MyA CO",<span className='subscript50'>2</span>],
        arrowTxt : "Saber más",
        txt : ["La primera gama de abatidores multifunción de carro de CO",<sub>2</sub>,".", <br></br> ,"Mayor velocidad de abatimiento, menor consumo energético, cero impacto ambiental."]
    },

    propsGwp : {
        title : "Una solución respetuosa con el medio ambiente.",
        description : ["El gas CO",<sub>2</sub>," es un refrigerante natural y ambientalmente seguro con un GWP de 1. El potencial de calentamiento global (GWP) de un gas es la capacidad relativa de un gas para atrapar calor dentro de la atmósfera con el tiempo, en comparación con el CO",<sub>2</sub>,". Cuanto mayor sea el valor del índice GWP, mayor será el impacto de un gas en el calentamiento global."],
        grafico1 : {
            img: "",
            title: "title",
            txt: ""
        },
        grafico2 : {
            img: ImmagineGwp,
            title: "Global Warming Potential (GWP)",
            txt: ["El Potencial de Calentamiento Global (GWP) expresa la contribución al efecto invernadero de un gas, relativo al efecto del CO",<sub>2</sub>,", cuyo potencial de referencia es igual a 1. Entonces cada uno de los gases liberados en la atmósfera tiene su propio GWP que describe su propio efecto de alteración del clima. Todos los gases, con sus propios GWP, junto con los CO",<sub>2</sub>," liberado presentando GWP 1 para definición, forma el problema equivalentes de CO",<sub>2</sub>," totales"]
        },
        grafico3 : {
            img: "",
            title: "title",
            txt: ""
        },
    },

    propsVantaggi : {
        titleTxt : [
            ["¿Por qué elegir el gas CO",<sub>2</sub>,"?"],
            ["Una elección responsable."],
            "Facil mantenimiento.",
            "Menos impacto, consumo reducido."
        ],
        descTxt : [
            ["El gas CO",<sub>2</sub>," es un refrigerante natural, seguro para el medio ambiente. No inflamable, no tóxico y un 20% más eficiente."],
            ["El uso de CO",<sub>2</sub>," como gas refrigerante en diversas aplicaciones es cada vez más creciente, debido a que los gases refrigerantes HFC actuales, como el R404A, tendrán que eliminarse gradualmente a corto plazo."],
            ["Además de ser un gas fácilmente disponible, los costos de el mantenimiento es significativamente menor, especialmente en caso de fugas ."],
            ["Gracias a sus características físicas, el gas CO",<sub>2</sub>," absorbe más calor que los gases tradicionales del mercado, como el R404A y el R452A. Además, permite el uso de compresores que consumen menos electricidad."]
        ]
    },
    
    propsMyA : {
        title: ["MultiFresh® MyA CO",<sub>2</sub>],
        txt : [
            "El asistente multifuncional inimitable."
            ,<br></br>,<br></br>,
            "Es la primera gama de abatidores multifunción de carro con gas refrigerante R744, más conocido como CO",<sub>2</sub>,". Es el abatidor multifuncional. El abatidor de CO",<sub>2</sub>," MultiFresh® MyA solo está disponible en la versión multifunción PLUS que incluye no solo las funciones de frío sino también de calor y es capaz de gestionar ciclos de funcionamiento con temperaturas de +85 °C a -35 °C."
            ,<br></br>,<br></br>,
            "Con solo presionar un botón, puede enfriar, congelar, levantar, descongelar, regenerar, pasteurizar y cocinar a baja temperatura."
            ,<br></br>,<br></br>,
            "Todas las funciones han sido minuciosamente probadas en colaboración con los más prestigiosos profesionales de la restauración. Para cada alimento y para cada proceso de producción hemos establecido la temperatura correcta, la ventilación adecuada y el nivel de humedad ideal para una instantánea perfecta de su calidad."
            ,<br></br>,<br></br>,
            "Nuestros abatidores rápidos garantizan la extracción de calor más rápida, incluso en presencia de productos calientes, sin dañar los alimentos de ninguna manera y respetando el medio ambiente."
        ]
    },

    propsPerformance : {
        title : ["MultiFresh® MyA CO",<sub>2</sub>," es eficiencia y rendimiento"],
        subtitle : [
            "Más rápido",
            "Sustentabilidad",
            "Eficiencia"
        ],
        txt : [
            ["El gas CO",<sub>2</sub>," no solo lo ayuda a ser más ecológico, sino que es extremadamente efectivo al ofrecerle un mejor rendimiento."],
            ["Trabajamos todos los días para cuidar nuestro planeta. Con los abatidores de temperatura CO",<sub>2</sub>," IRINOX usted también tendrá la oportunidad de contribuir a este cambio."],
            ["Elegir CO",<sub>2</sub>," MultiFresh® también significa reducir el consumo de energía. Hasta un 50% respecto a la gama anterior."]
        ]
    },

    propsComposizione : {
        title : ["Soluciones IRINOX"],
        subtitle : [
            "Evaporadores",
            "Unidad condensadora",
            "Enfriador (opcional)",
            "Il display"
        ],
        desc : [
            ["Los ventiladores del evaporador son totalmente de aluminio, con equilibrado de las palas de cobre y/o acero inoxidable, garantizando higiene y fácil limpieza."],
            ["La unidad condensadora IRINOX está equipada con un cuadro eléctrico completo con todos los dispositivos de seguridad y está íntegramente construido y probado al 100% por IRINOX en sus laboratorios junto con la célula."],
            ["Es posible suministrar agua de refrigeración a la unidad de condensación de CO",<sub>2</sub>," mediante la conexión de un enfriador dedicado o el uso de enfriadores HVAC centralizados existentes. Alternativamente, es posible conectar un sistema geotérmico existente."],
            ["MyA es la nueva interfaz táctil de los enfriadores rápidos multifunción MultiFresh® que facilita aún más el uso de la máquina, de forma simple e intuitiva.",<br></br>,"Los iconos guían al operador a través de las numerosas funciones con una amplia selección de ciclos, estudiados para respetar las cualidades organolépticas de cada alimento.",<br></br>,"Solo con MyA se consigue la máxima personalización: se podrán cambiar los parámetros de cada ciclo con facilidad y crear el proceso ideal para cada producto."]
        ],
        txtChiller: "Uno de estos tres componentes debe estar conectado para completar la instalación.",
        btnTxt: [
            "ENFRIADOR DEDICADO",
            "ENFRIADOR PERSONALIZADO",
            "SISTEMA GEOTÉRMICO"
        ]
    },

    propsFooter : {
        txt : ["Irinox nace en 1989 en Carbonese, Italia, y es reconocido en todo el mundo como el especialista de los enfriadores y congeladores rápidos de temperatura. La producción se realiza completamente en nuestras plantas en Italia, en un área de 9000 m",<sup>2</sup>," a las puertas de Treviso."],
        address : ["Via Caduti nei Lager, 1",<br></br>,"31015 Conegliano (TV) - Italy",<br></br>,"Tel. +39 0438 2020",<br></br>,"Fax +39 0438 2023"],
        map : ["MOSTRAR EN EL MAPA","https://www.google.com/maps/place/Irinox+SpA+-+Business+Unit+Professional/@45.9555145,12.312648,15z/data=!4m5!3m4!1s0x0:0x9caf1aa567fe6666!8m2!3d45.9555145!4d12.312648"],
        assistenza : [
            "ASISTENCIA",
            "https://www.irinoxprofessional.com/spa/solicitar-asistencia"
        ],
        service : [
            "SERVICE",
            "https://www.irinoxprofessional.com/spa/service"
        ],
        contattaci : [
            "CONTÁCTANOS",
            "https://www.irinoxprofessional.com/spa/contactos"
        ],
        fb : "https://www.facebook.com/irinoxprofessional",
        in : "https://www.linkedin.com/showcase/18135187/admin/updates/",
        ig : "https://www.instagram.com/irinox_professional/",
        yt : "https://www.youtube.com/user/Irinoxprofessional"
    }
}

export default propsLanding;